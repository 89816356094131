import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Menu, MenuItem, IconButton, } from '@mui/material';
import { color, styled } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { removeSession } from '../../instruments';
import { apiActions } from '../../bus/actions';
import { useTranslation } from 'react-i18next';  // Імпортуємо i18n
import header from './header.png'
import Cookies from 'js-cookie'; // Імпортуємо бібліотеку
import './header.css'


const StyledButton = styled(Button)`
  color: 0064b5;
  font-size: 16px;
  margin: 0 10px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;



function Header() {
    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();  // Використовуємо useTranslation

    const selectState = (state) => ({
        userData: state.swapi.userData,
        lang: state.swapi.lang,  // Отримуємо мову з глобального стейту
    });

    const state = useSelector(selectState);
    const { userData, lang } = state;
    const [selectedLang, setSelectedLang] = useState(lang || 'en');  // Стан для вибору мови

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };




    const logout = () => {
        handleMenuClose();
        removeSession();
        dispatch(apiActions.setIsLogged(false));
        dispatch(apiActions.setUserData(null));
        navigate('/');
    };

    const changeLanguage = (event) => {
        const lang = event.target.value;
        setSelectedLang(lang);  // Оновлюємо локальний стан для мови
        dispatch(apiActions.setLanguage(lang));  // Оновлюємо Redux
        i18n.changeLanguage(lang);  // Оновлюємо i18next
        Cookies.set('lang', lang, { expires: 365 });  // Зберігаємо мову на рік
    };

    useEffect(() => {
        dispatch(apiActions.setLanguage(selectedLang));  // Зберігаємо вибрану мову в глобальному стейті
        i18n.changeLanguage(selectedLang);  // Оновлюємо i18next для зміни мови
    }, [selectedLang, dispatch, i18n]);
    useEffect(() => {
        setSelectedLang(lang)
    }, [lang]);
    return (
        <>
            <div className='header'>

                <a className='logo' href='/'>
                    <img className='heager_emblema' src={header} alt='header'></img>
                    <span>EasyToVPN</span></a>
                <div className='registration'>
                    {userData ? (
                        <>
                            <IconButton
                                sx={{ color: '#0064b5', marginRight: '20px' }}
                                onClick={handleMenuOpen}>
                                <AccountCircle fontSize='large' />
                            </IconButton>
                            <Menu
                                PaperProps={{
                                    style: {
                                        backgroundColor: '#333',
                                        color: '#fff',
                                    },
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}>
                                <MenuItem
                                    component={Link}
                                    style={{ color: '#fff' }}
                                    to={'/admin/user/' + userData._id}
                                    onClick={handleMenuClose}>
                                    {t('profile')}
                                </MenuItem>
                                <MenuItem
                                    style={{ color: '#fff' }}
                                    onClick={logout}>
                                    {t('logout')}
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <a className='login' href='login'>{t('login')}</a>
                            <a className='login reg' href='login'>{t('register')}</a>
                        </>
                    )}
                    <select
                        id="lang-select"
                        value={selectedLang}
                        onChange={changeLanguage}
                    >
                        <option value="en">EN</option>
                        <option value="ua">UA</option>
                        <option value="ru">RU</option>
                    </select>
                </div>
            </div>

        </>
    );
}

export default Header;
