import React, { useState, useEffect } from 'react';
import { api } from '../../../Api';
import './Single.css';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';
import { useTranslation } from 'react-i18next';
import PersonalInfo from './personal';
import { useSelector } from 'react-redux';
import info from './icon/info.png';
import infinity from './icon/infinity.png';
import star1 from '../../Home/star1.png';
import star2 from '../../Home/star2.png';
import star3 from '../../Home/star3.png';
import star4 from '../../Home/star4.png';
import copy from './icon/copy.png';
import filter from './icon/filter.png';
import france from '../../iconCountry/icon/france.png';
import great_britain from '../../iconCountry/icon/great_britain.png';
import netherlands from '../../iconCountry/icon/netherlands.png';
import servers from '../../Home/servers.png';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import en from 'date-fns/locale/en-GB';
registerLocale('en', en);

const selectState = (state) => ({
    userData: state.swapi.userData,
});

function Single() {
    const state = useSelector(selectState);
    const { userData } = state;

    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const { t } = useTranslation();
    const id = window.location.pathname.split('/').pop();
    const [data, setData] = useState(null);
    const [tariffData, setTariffData] = useState(null);
    const [isModalTariffOpen, setIsModalTariffOpen] = useState(false);
    const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);
    const [isMonthsModalOpen, setIsMonthsModalOpen] = useState(false); // стан для модального вікна вибору місяців
    const [visibleItems, setVisibleItems] = useState(2);
    const [visibleTransactions, setVisibleTransactions] = useState(2);
    const [selectedMonths, setSelectedMonths] = useState(1);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [tariffsList, setTariffsList] = useState([]);
    const [transactionsList, setTransactionsList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date()); // Стан для вибраної дати
    const [filterTariff, setFilterTariff] = useState({});
    const [filterTransaction, setFilterTransaction] = useState({});
    const [isModal, setIsModal] = useState(false);
    const [tariffContinion, setTariffContinion] = useState(null);
    const [isModalInfo, setIsModalInfo] = useState(false);
    const [historyList, setHistoryList] = useState([]);
    const [isCheck, setIsCheck] = useState(false);


    const showModalInfo = async (tariff_id) => {
        const data = await api.postData(
            { userId: id, tariff_id: tariff_id },
            '/historyTariffContinue',
        );
        setHistoryList(data.data)
        setIsModalInfo(true);
    };
    const hideModalInfo = () => {
        setIsModalInfo(false);
    };
    const tariffs = {
        Basic: { price: 5, title: 'basicPlan' },
        Family: { price: 10, title: 'familyPlan' },
        Premium: { price: 25, title: 'premiumPlan' },
        dedicatedServer: {
            title: 'dedicatedServer',
            // link: t('pricing.dedicatedServer.tg_conttact'),
            text: t('contactUs')
        },
    };
    const tariffIcons = {
        Basic: star1,
        Family: star2,
        Premium: star3,
        dedicatedServer: star4,
    };

    const copyToClipboard = (event, text) => {
        const { clientX, clientY } = event;
        setMousePosition({ x: clientX + 10, y: clientY + 10 });
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setShowCopyMessage(true);
                setTimeout(() => {
                    setShowCopyMessage(false);
                }, 3000);
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };
    const hideModal = () => {
        setIsModal(false)
    };

    const showModal = (item) => {
        setSelectedTariff(item.tariff)
        setTariffContinion(item)
        setIsModal(true)
    };

    const continue_tariff = () => {
        confirmAlert({
            title: t('confirmAlert.title'),
            message: `${t('confirmAlert.message')} ${selectedMonths} ${t('confirmAlert.months')}`,
            overlayClassName: 'confirmAlertOverlay',
            buttons: [
                {
                    label: t('confirmAlert.yes'),
                    onClick: async () => {
                        setIsModal(false)
                        const writeOffBalance = await api.postData(
                            { userId: id, tariff: tariffContinion, mounth: selectedMonths },
                            '/payment/writeOffBalance_continue',
                        );
                        if (writeOffBalance) {
                            setTariffData(writeOffBalance);
                            setIsModalTariffOpen(true);
                        }
                    },
                    className: 'confirm',
                },
                {
                    label: t('confirmAlert.no'),
                    className: 'cancel',
                },
            ],
        });


    };
    const handleConfirmAlert = (plan, amount, location) => {

        if (isCheck) {
            confirmAlert({
                title: t('confirmAlert.title'),
                message: `${t('confirmAlert.message')} ${selectedMonths} ${t('confirmAlert.months')}`,
                overlayClassName: 'confirmAlertOverlay',
                buttons: [
                    {
                        label: t('confirmAlert.yes'),
                        onClick: async () => {
                            const writeOffBalance = await api.postData(
                                { userId: id, plan: plan, amount: amount, mounth: selectedMonths, location: location },
                                '/payment/writeOffBalance',
                            );
                            if (writeOffBalance) {
                                setTariffData(writeOffBalance);
                                setIsModalTariffOpen(true);
                            }
                        },
                        className: 'confirm',
                    },
                    {
                        label: t('confirmAlert.no'),
                        className: 'cancel',
                    },
                ],
            });
            setIsMonthsModalOpen(false);
        } else {
        }

    };

    const getData = async () => {
        const data = await api.postData({ type: 'user' }, '/user/' + id);
        if (data.data) {
            setData(data.data);
            if (data.data.tariffs) {
                setTariffsList(data.data.tariffs)
            }
            if (data.data.transactions) {
                setTransactionsList(data.data.transactions)
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);


    const handleTariff = (tariffText, tariff) => {
        if (tariff.link) {
            window.open(tariff.link, '_blank');
            return;
        }
        setSelectedTariff(tariffText);
        setIsMonthsModalOpen(true); // відкриваємо модальне вікно для вибору місяців
    };

    const confirmTariffSelection = () => {
        if (selectedTariff) {
            const amount = tariffs[selectedTariff].price * selectedMonths;
            if (data.balance >= amount) {
                handleConfirmAlert(selectedTariff, amount, selectedLocation);
            } else {
                setIsInsufficientFunds(true);
                setIsMonthsModalOpen(false);
            }

        }
    };

    const closeModal = () => {
        setIsInsufficientFunds(false);
        setIsMonthsModalOpen(false);
        setIsModalTariffOpen(false);
        setTariffData(null);
    };

    const tariffJSX = Object.keys(tariffs).map((el) => {
        const tariff = tariffs[el];
        const customClass = el === 'dedicatedServer' ? 'dedicated' : el.toLowerCase();
        return (
            <div key={el} className={`pricing-card ${customClass}`}>
                <div
                    style={{
                        background:
                            el === 'dedicatedServer'
                                ? 'linear-gradient(180deg, #5e17eb, #31087b)'
                                : el === 'Premium'
                                    ? 'linear-gradient(180deg, #32a852, #74db8c)'
                                    : el === 'Family'
                                        ? 'linear-gradient(180deg, #a58eff, #e3c2ff)'
                                        : 'linear-gradient(to bottom, #da77f2, #ff77a9)',
                    }}
                >
                    <div style={{ padding: '52px' }}>
                        {el === 'dedicatedServer' ? (
                            <div className="pricing-header-dedicated">
                                <img src={servers} alt="servers" />
                                <p className="pricing-servers-dedicated">{t('pricing.dedicatedServer.price')}</p>
                            </div>
                        ) : (
                            <div className={`pricing-header pricing-header-${customClass}`}>
                                <p className={`pricing-price pricing-price-${customClass}`}>
                                    {tariff.price ? `$${tariff.price} ${t('per_mounth')}` : t('custom_pricing')}
                                </p>
                            </div>
                        )}
                    </div>
                    <h3 className={`pricing-title pricing-title-${customClass}`}>
                        {t(`pricing.${tariff.title}.title`)}
                    </h3>
                </div>

                <div className="pricing-features">
                    <div className="info_tariff">
                        <img src={tariffIcons[el]} alt={`feature-${el}`} />
                        {t(`pricing.${tariff.title}.traffic`)}
                    </div>
                    <div className="info_tariff">
                        <img src={tariffIcons[el]} alt={`feature-${el}`} />
                        {t(`pricing.${tariff.title}.description1`)}
                    </div>
                    <div className="info_tariff">
                        <img src={tariffIcons[el]} alt={`feature-${el}`} />
                        {t(`pricing.${tariff.title}.description2`)}
                    </div>

                    {el === 'dedicatedServer' ? (
                        <a
                            className="contact-link-dedicated"
                            target="_blank"
                            rel="noreferrer"
                            href={t('pricing.dedicatedServer.tg_conttact')}
                        >
                            {t('pricing.dedicatedServer.tg_conttact')}
                        </a>
                    ) : (
                        <button className={`pricing-btn pricing-btn-${customClass}`} onClick={() => handleTariff(el, tariff)}>
                            {t('banner.buyNow')}
                        </button>
                    )}
                </div>
            </div>
        );
    });
    const showMoreItems = () => {
        setVisibleItems((prevCount) => prevCount + 2);
    };


    const showMoreTransactions = () => {
        setVisibleTransactions((prevCount) => prevCount + 2);
    };


    const filterFunc = (type, filterList) => {
        let arr = [];
        if (type === 'tariff') {
            arr = data?.tariffs;
        } else {
            arr = data?.transactions;
        }

        for (const name of Object.keys(filterList)) {
            const value = filterList[name]
            if (name && value) {
                if (name === 'plan') {
                    arr = arr.filter((el) => el.tariff === value)
                }
                if (name === 'status') {
                    arr = arr.filter((el) => el.status === value)
                }

                if (name === 'date') {
                    setSelectedDate(value);
                    const getMonthYear = (date) => {
                        const d = new Date(date);
                        return {
                            month: d.getMonth(), // 0-11
                            year: d.getFullYear(),
                        };
                    };

                    const selectedMonthYear = getMonthYear(value); // Отримуємо місяць і рік з обраної дати

                    arr = arr.filter((el) => {
                        const date = type === 'tariff' ? el.start_date : el.createdAt

                        const tariffMonthYear = getMonthYear(date); // Отримуємо місяць і рік з дати тарифу

                        return (
                            tariffMonthYear.month === selectedMonthYear.month &&
                            tariffMonthYear.year === selectedMonthYear.year
                        );
                    });


                }
                console.log(arr, type)


            }

        }
        if (type === 'tariff') {

            setTariffsList(arr)
        } else {
            setTransactionsList(arr)
        }


    };

    const onChange = (name, value, type) => {
        if (name && value) {
            let newFilter = {}
            if (type === 'tariff') {
                newFilter = filterTariff
                newFilter[name] = value
                setFilterTariff(newFilter)
            }
            else {
                newFilter = filterTransaction
                newFilter[name] = value
                setFilterTransaction(newFilter)
            }
            if (Object.keys(newFilter).length > 0) {
                filterFunc(type, newFilter)

            }


        }
    };



    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            style={{
                backgroundColor: '#212139',
                padding: '8px 20px',
                borderRadius: '25px',
                color: '#fff',
                fontSize: '16px',
                fontWeight: 'bold',
                border: '0'
            }}
            onClick={onClick}
            ref={ref}
            value={value}
            readOnly
        />
    ));

    const [selectedLocation, setSelectedLocation] = useState('Netherlands');
    const [selectedImage, setSelectedImage] = useState(netherlands);

    const locations = [
        { value: 'Netherlands', label: 'Netherlands', img: netherlands },
        { value: 'France', label: 'France', img: france },
        { value: 'Great Britain', label: 'Great Britain', img: great_britain },
    ];

    const getLocationImage = (locationName) => {
        const location = locations.find((loc) => loc.value === locationName);
        return location ? location.img : null;
    };

    const handleChange = (e) => {
        const selected = locations.find((location) => location.value === e.target.value);
        setSelectedLocation(selected.value);
        setSelectedImage(selected.img);
    };


    return data && Object.keys(data).length > 0 ? (
        <div className='profile-container'>
            <h1 className='title_h1'>{t('dashboard')}</h1>
            <PersonalInfo
                data={data}
                t={t}
            />

            <h1 className='title_h1'>{t('selectPlan')}</h1>

            <section className='pricing-options'>
                <div className='pricing-options'>{tariffJSX}</div>
            </section>

            {isMonthsModalOpen && (
                <div className='modal'>

                    <div className='modal-content'>
                        <span className='close' onClick={closeModal}>&times;</span>
                        <h2>{t('selectMonths')}</h2>
                        <div className="service-item">
                            <div className="service-columns">
                                <div className="service-el">
                                    <strong>Selected Location:</strong>
                                    <div className='selectedLocationBlock'>
                                        <select
                                            style={{ width: '80%' }}
                                            className="location-select"
                                            value={selectedLocation}
                                            onChange={handleChange}
                                        >
                                            {locations.map((location) => (
                                                <option key={location.value} value={location.value}>
                                                    {location.label}
                                                </option>
                                            ))}
                                        </select>
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: 'center' }}>
                                            <img
                                                src={selectedImage}
                                                alt={selectedLocation}
                                                style={{ width: '25px', objectFit: 'contain', marginRight: '10px' }}
                                            />
                                            <span>{selectedLocation}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="service-item">
                            <div className="service-columns">
                                <div className="service-el">
                                    <strong>{t('tariff')}:</strong>
                                    <span style={{ width: '75%' }}>
                                        <div style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ padding: 0 }}>{t(`pricing.${tariffs[selectedTariff].title}.title`)}</span>
                                            <span style={{ padding: 0 }}>{t(`pricing.${tariffs[selectedTariff].title}.price`)}</span>
                                        </div>


                                    </span>
                                </div>

                            </div>
                            <div style={{ textAlign: 'right', fontWeight: 600 }}>Dedicated Server: https://t.me/vpnMyServiceBot</div>

                        </div>
                        <div className="service-item">
                            <div className="service-columns">
                                <div className="service-el">
                                    <strong>{t('period')}:</strong>
                                    <span style={{ width: '75%' }}>
                                        <label>
                                            <input
                                                className='selectMonths'
                                                type='number'
                                                value={selectedMonths}
                                                onChange={(e) => setSelectedMonths(Number(e.target.value))}
                                                min='1'
                                            />
                                        </label>

                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className='errorCheck' style={{ color: !isCheck ? 'red' : 'black', }}>
                            <input
                                style={{ marginRight: '20px' }}
                                checked={!!isCheck}
                                type='checkbox'
                                required
                                onChange={() => setIsCheck(!isCheck)}
                            />
                            I agree to the terms of use and have read the rules for using the service
                        </div>
                        <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 600, width: '100%' }}>

                            TOTAL: ${selectedMonths * tariffs[selectedTariff].price}
                        </div>
                        <button onClick={confirmTariffSelection} className='confirm-btn'>
                            {t('confirmSelection')}
                        </button>
                    </div>
                </div>
            )}


            {isModalTariffOpen && tariffData && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={closeModal}>&times;</span>

                        {tariffData.continue ? (
                            <>
                                <h2>{t('subscriptionConfirmation')}</h2>
                                <p>
                                    {t('successfullyActivated')} <strong>{selectedTariff}</strong> {t('forMonths')} <strong>{selectedMonths}</strong> {t('months')}.
                                </p>
                            </>
                        ) : null}
                        {tariffData.accessUrl ? (
                            <>
                                <h2>{t('subscriptionConfirmation')}</h2>
                                <p>
                                    {t('successfullyActivated')} <strong>{selectedTariff}</strong> {t('forMonths')} <strong>{selectedMonths}</strong> {t('months')}.
                                </p>
                                <p>{t('yourAccessCode')}:</p>
                                <div className="access-code">
                                    <strong>{tariffData.accessUrl}</strong>
                                </div>
                                <p>{t('saveAccessCode')}</p>
                            </>
                        ) : null}
                        {!tariffData.continue && !tariffData.accessUrl ? (
                            <>
                                <h2>{t('error')}</h2>
                            </>
                        ) : null}

                    </div>
                </div>
            )}

            {isInsufficientFunds && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={closeModal}>&times;</span>
                        <h2>{t('insufficientFunds')}</h2>
                        <p>{t('notEnoughBalance')}</p>
                    </div>
                </div>
            )}

            <section className="service-history">
                <h1 className='title_h1'>{t('tariffsHistory')}</h1>
                <div className='filter_service'>
                    <div className='selectFilter'>
                        <strong style={{ fontSize: '16px' }}>  {t('selectFilter.plan')}</strong>
                        <select onChange={(el) => onChange('plan', el.target.value, 'tariff')}>
                            <option value=''>All</option>
                            <option value='Basic'>{t('pricing.basicPlan.title')}</option>
                            <option value='Family'>{t('pricing.familyPlan.title')}</option>
                            <option value='Premium'>{t('pricing.premiumPlan.title')}</option>
                        </select>
                    </div>
                    <div className='selectFilter'>
                        <strong style={{ fontSize: '16px' }}>{t('selectFilter.status')}</strong>
                        <select onChange={(el) => onChange('status', el.target.value, 'tariff')}>
                            <option value=''>All</option>
                            <option value='active'>active</option>
                            <option value='disabled'>disabled</option>
                        </select>

                    </div>
                    <div className='selectFilter'>
                        <strong style={{ fontSize: '16px' }}>{t('selectFilter.date')}</strong>
                        <DatePicker
                            dateFormat={'MM.yyyy'}
                            locale='en'
                            selected={selectedDate}
                            showMonthYearPicker
                            onChange={(date) => onChange('date', date, 'tariff')}
                            customInput={<CustomInput />}
                        />

                    </div>
                    <div className='selectFilter selectFilterImg'><img src={filter} onClick={(e) => setFilterTariff({})} alt="" /></div>

                </div>
                {tariffsList?.length > 0 ? (
                    <div className="tariff-list">
                        {tariffsList.slice(0, visibleItems).map((item, index) => (
                            <>
                                {item?.vpnData ? (
                                    <div className="service-item" key={index}>
                                        <div className='info' >
                                            <img src={info} alt='info' onClick={() => showModalInfo(item._id)}></img>
                                        </div>
                                        {isModalInfo && (
                                            <div className="infoTariff">
                                                <div className="closeInfoTariff" onClick={hideModalInfo}>
                                                    &times;
                                                </div>
                                                <div className="historyList">
                                                    {historyList && historyList.length > 0 ? (
                                                        historyList.map((item, index) => (
                                                            <div key={index} className="historyItem">
                                                                <div className='historyEl'>
                                                                    <strong>{t('infoTariff.tariff')}</strong>
                                                                    <div>{item.tariff_id.tariff}</div>
                                                                </div>
                                                                <div className='historyEl'>
                                                                    <strong>{t('infoTariff.amount')}</strong>
                                                                    <div>{item.amount}</div>
                                                                </div>
                                                                <div className="historyEl">
                                                                    <strong>{t('infoTariff.date')}</strong>
                                                                    <div>
                                                                        {new Date(item.createdAt).toLocaleDateString('uk-UA')}
                                                                    </div>
                                                                </div>
                                                                <div className="historyEl">
                                                                    <strong>{t('infoTariff.finish_date')}</strong>
                                                                    <div>
                                                                        {new Date(item.finish_date).toLocaleDateString('uk-UA')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <h1 style={{
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            marginBottom: "10px"
                                                        }}>{t('infoTariff.noHistory')}</h1>
                                                    )}
                                                </div>

                                            </div>
                                        )}
                                        <div className="service-columns">
                                            <div className="column">
                                                <div className="service-el">
                                                    <strong>{t('service')}</strong>
                                                    <span>{item.tariff}</span>
                                                </div>
                                                <div className="service-el">
                                                    <strong>{t('startDate')}:</strong>
                                                    <span className="start-date">{new Date(item.start_date).toLocaleDateString()}</span>
                                                </div>
                                                <div className="service-el">
                                                    <strong>{t('finishDate')}:</strong>
                                                    <span className="finish-date">{new Date(item.finish_date).toLocaleDateString()}</span>
                                                </div>
                                                <div className="service-el">
                                                    <strong>{t('amount')}:</strong>
                                                    <span className="amount">${item.sum}</span>
                                                </div>
                                            </div>

                                            <div className="column status-column">
                                                <div className="service-el">
                                                    <strong>{t('status')}:</strong>
                                                    <span className={`status ${item.status.toLowerCase()}`}>{item.status}</span>
                                                </div>
                                                <div className="service-el">
                                                    <strong>{t('limitTariff')}:</strong>
                                                    <span className="limitTariff">
                                                        {item.tariff === "Premium"
                                                            ? <img src={infinity} alt='infinity'></img>
                                                            : `${(item?.vpnData?.dataLimit?.bytes / (1024 ** 3)).toFixed(2)} GB`}
                                                    </span>
                                                </div>

                                                <div className="service-el">
                                                    <strong>{t('days')}:</strong>
                                                    <span className="daysOfused">
                                                        {Math.ceil(
                                                            (new Date(item.finish_date) - new Date()) / (1000 * 60 * 60 * 24)
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="service-el">
                                                    <strong>{t('location')}:</strong>
                                                    <span className="location loc" style={{ display: 'flex', alignItems: 'center' }}>
                                                        {item.location}
                                                        <img
                                                            src={getLocationImage(item.location)}
                                                            alt={item.location}
                                                            style={{ width: '25px', marginLeft: '10px' }}
                                                        />

                                                    </span>
                                                </div>
                                            </div>

                                            <div className="column button-column">
                                                <button
                                                    onClick={() => showModal(item)}
                                                    className="continue-tariff-btn"
                                                >
                                                    {t('continue')}
                                                </button>
                                            </div>
                                        </div>

                                        {item?.vpn?.accessUrl && (
                                            <div className="vpn-details">
                                                <strong>{t('vpnCode')}:</strong>
                                                <span>
                                                    {item.vpn.accessUrl}
                                                    <img
                                                        style={{ cursor: 'pointer' }}
                                                        src={copy}
                                                        alt=""
                                                        onClick={(e) => copyToClipboard(e, item.vpn.accessUrl)}
                                                    />
                                                </span>
                                            </div>
                                        )}

                                        {showCopyMessage && (
                                            <div
                                                className="copy-message"
                                                style={{
                                                    top: mousePosition.y,
                                                    left: mousePosition.x,
                                                }}
                                            >
                                                {t('copy')}
                                            </div>
                                        )}

                                        {isModal && (
                                            <div className="modalContinion">
                                                <h1 style={{ fontWeight: 'bold', marginBottom: '20px' }}>{t('selectOfMonths')}</h1>
                                                <input
                                                    type="number"
                                                    min="1"
                                                    value={selectedMonths}
                                                    onChange={(e) => setSelectedMonths(Number(e.target.value))}
                                                />
                                                <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 600, marginTop: '25px' }}>

                                                    TOTAL: ${Number(selectedMonths) * (tariffs[selectedTariff]?.price || 0)}

                                                </div>
                                                <div>
                                                    <button
                                                        className="btnOK"
                                                        onClick={() => continue_tariff()}
                                                    >
                                                        OK
                                                    </button>
                                                    <button
                                                        className="btnOK btnCancel"
                                                        onClick={hideModal}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </>

                        ))}

                    </div>
                ) : (
                    <p>{t('noServiceHistory')}</p>
                )
                }

                {
                    tariffsList && visibleItems < tariffsList.length && (
                        <div className="show-more-container">
                            <button className="show-more-btn" onClick={showMoreItems}>
                                {t('showMore')}
                            </button>
                        </div>
                    )
                }

            </section >







            <section className="transaction-filter_service">
                <h1 className="title_h1">{t('transactionsHistory')}</h1>
                <div className="filter_service">

                    <div className='selectFilter'>
                        <strong style={{ fontSize: '16px' }}>{t('selectFilter.status')}</strong>
                        <select onChange={(el) => onChange('status', el.target.value, 'transactions')}>
                            <option value=''>All</option>
                            <option value='pending'>pending</option>
                            <option value='success'>success</option>
                        </select>

                    </div>
                    <div className='selectFilter'>
                        <strong style={{ fontSize: '16px' }}>{t('selectFilter.date')}</strong>
                        <DatePicker
                            dateFormat={'MM.yyyy'}
                            locale='en'
                            selected={selectedDate}
                            showMonthYearPicker
                            onChange={(date) => onChange('date', date, 'transactions')}
                            customInput={<CustomInput />}
                        />

                    </div>
                    <div className='selectFilterImg'><img src={filter} onClick={(e) => {
                        setFilterTransaction({})
                        filterFunc('transactions', {})
                    }} alt="" /></div>

                </div>
                {transactionsList?.length > 0 ? (
                    <div className="transaction-list">
                        {transactionsList.slice(0, visibleTransactions).map((item, index) => (
                            <div className="transaction-item" key={index}>
                                <div className="transaction-columns">
                                    <div className="column_transaction">
                                        <div className="transaction-el">
                                            <strong>{t('date')}:</strong>
                                            <span>{new Date(item.createdAt).toLocaleDateString()}</span>
                                        </div>
                                        <div className="transaction-el">
                                            <strong>{t('amount')}:</strong>
                                            <span className="amount">${item.amount}</span>
                                        </div>



                                        <div className="transaction-el">
                                            <strong>{t('status')}:</strong>
                                            <span
                                                className={`status ${item.status.toLowerCase()}`}
                                                style={{
                                                    color: item.status === 'success' ? 'green' :
                                                        item.status === 'pending' ? 'orange' : 'red'
                                                }}
                                            >
                                                {item.status === 'success' ? (
                                                    t('transactionsStatu.success')
                                                ) : (
                                                    t('transactionsStatu.pending')
                                                )}
                                            </span>
                                        </div>





                                        <div className="transaction-el">
                                            <strong>{t('transactionId')}:</strong>
                                            <div className='transactionID' style={{ display: 'flex' }}>
                                                <span>{item.txn_id}</span>
                                                <img
                                                    className='transactionIDImg'
                                                    src={copy}
                                                    alt=''
                                                    onClick={(e) => copyToClipboard(e, item.txn_id)}>

                                                </img>
                                            </div>
                                        </div>
                                        {item.status === 'pending' && (
                                            <div className="transaction-el">
                                                <strong>{t('address')}:</strong>
                                                <div style={{ display: 'flex' }}>
                                                    <span>{item.address}</span>
                                                    <img
                                                        style={{ marginLeft: '10px', cursor: "pointer" }}
                                                        src={copy}
                                                        alt=''
                                                        onClick={(e) => copyToClipboard(e, item.address)}></img>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    {item.status === 'pending' && (
                                        <div className="column_transaction qr-code-column">
                                            <div className="qr-code">
                                                <img alt={t('qrCodeAlt')} src={item.qrcode_url} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>{t('noTransactionHistory')}</p>
                )}
                {transactionsList && visibleTransactions < transactionsList.length && (
                    <div className="show-more-container">
                        <button className="show-more-btn" onClick={showMoreTransactions}>
                            {t('showMore')}
                        </button>
                    </div>
                )}
            </section>

        </div >
    ) : null;
}

export default Single;
