// i18n.js
import { Block, Pending } from '@mui/icons-material';
import i18n from 'i18next';
import { title } from 'process';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        transactionsStatu: {
          success: 'success',
          pending: 'pending'
        },
        contactPage: {
          title: 'Contact us',
          follow: 'Follow us',
          address: 'Адрес:',
          sendMessage: 'Send message',
          name: 'Your name:',
          email: 'Type your e-mail',
          question: 'What question are you interested in:',
          message: 'Message',
          enterMessage: 'Enter your message',
          sendBtn: 'Send'

        },
        termsOfUse: {
          title: "Terms of Use",
          introduction: {
            title: "1. Introduction",
            text: "We are glad that you have chosen to use EasyToVPN! These Terms of Use ('Terms') govern access to and use of EasyToVPN's websites, applications, and services (collectively referred to as 'Services'). By accessing our websites or using our Services, you agree to comply with these Terms and our Privacy Policy. If you do not agree with these Terms, please do not use EasyToVPN.",
            text2: "We may update these Terms from time to time. We will post the revised Terms on our website and update the 'Last Updated' date below. Your continued use of EasyToVPN after any changes to the Terms signifies your agreement to the new Terms. If you do not agree with the changes, you must stop using our Services."
          },
          ageRestrictionsAndRegistration: {
            title: "2. Age Restrictions and Registration",
            text: "You must be at least 18 years old to use EasyToVPN. If you are under 18, you may use our Services only under the supervision of a parent or guardian who agrees to be bound by these Terms.",
            registration: "To access certain features of EasyToVPN, you may need to create an account. You agree to keep your account information secure. You are responsible for all activities conducted through your account. If you suspect unauthorized access to your account, please contact us immediately."
          },
          ourServices: {
            title: "3. Our Services",
            text: "EasyToVPN provides virtual private network (VPN) services that encrypt your internet traffic and hide your IP address. When you connect to EasyToVPN, your data is routed through our secure servers located around the world. This helps protect your online privacy and security.",
            policy: "EasyToVPN adheres to a strict no-logs policy. We do not collect, store, or share any information about your online activities while connected to the VPN, including visited websites or their contents. We only retain the minimal information necessary for our Services to function.",
            responsibility: "We strictly oppose any illegal, criminal, or fraudulent activities conducted while using EasyToVPN. Since we do not store information about your activities, you are solely responsible for such actions."
          },
          acceptableUse: {
            title: "4. Acceptable Use",
            text: "When using EasyToVPN, you must comply with these Terms and all applicable laws and regulations. It is prohibited to:",
            restrictions: {
              illegalUse: "Use EasyToVPN for any illegal purposes or to transmit malicious code.",
              unauthorizedAccess: "Attempt unauthorized access to other systems.",
              spam: "Use our Services to send spam or phishing emails.",
              serviceInterference: "Interfere with the operation of our Services, such as by overloading servers."
            },
            actions: "EasyToVPN reserves the right to investigate and take appropriate legal action against anyone violating these Terms, including terminating their account without notice and reporting their actions to relevant authorities."
          },
          fairUse: {
            title: "5. Fair Use",
            text: "All EasyToVPN subscriptions are intended for reasonable personal use. Commercial use or unauthorized access by third parties is prohibited."
          },
          subscriptionsAndPayments: {
            title: "6. Subscriptions and Payments",
            text: "EasyToVPN offers various paid subscription plans. By purchasing a subscription to EasyToVPN, you agree to pay the applicable fees and taxes. Payments are processed by our trusted partners."
          },
          refundPolicy: {
            title: "7. Refund Policy",
            text: "If you are unable to fully utilize our paid Services due to our fault, we offer a 30-day money-back guarantee. Please contact our support team at easytovpn.com@gmail.com to request a refund."
          },
          intellectualProperty: {
            title: "8. Intellectual Property",
            text: "The EasyToVPN website, applications, services, and related content are the exclusive property of EasyToVPN. By using our Services, you do not gain any ownership rights to EasyToVPN."
          },
          disclaimerAndLimitationOfLiability: {
            title: "9. Disclaimer and Limitation of Liability",
            text: "EasyToVPN is provided 'as is' without warranties. We do not guarantee that our Services will operate without interruptions. EasyToVPN is not liable for any loss or damage of data resulting from the use of our Services."
          },
          indemnity: {
            title: "10. Indemnity",
            text: "You agree to indemnify, defend, and hold EasyToVPN harmless from any claims, damages, and liabilities arising from your use of our Services or breach of these Terms."
          },
          termination: {
            title: "11. Termination",
            text: "EasyToVPN reserves the right to suspend or terminate your access to our Services at any time without prior notice if you violate these Terms."
          },
          serviceModifications: {
            title: "12. Service Modifications",
            text: "EasyToVPN reserves the right to modify our Services at any time without prior notice."
          },
          governingLanguage: {
            title: "13. Governing Language",
            text: "The original language of these Terms of Use is English. In the event of discrepancies between the translation and the English version, the English version shall prevail."
          },
          contact: {
            title: "14. Contact",
            text: "If you have any questions, concerns, or feedback about these Terms or EasyToVPN, please contact our support team:",
            details: {
              email: "Email: easytovpn.com@gmail.com",
              chat: "Chat on our website: https://www.easytovpn.com",
              lastUpdated: "Last Updated: 10.12.2023"
            }
          }
        },
        privacyPolicy: {
          title: "Privacy Policy",
          introduction: {
            title: "1. Introduction",
            text: "This Privacy Policy explains how EasyToVPN ('EasyToVPN', 'we', 'us', or 'our') collects, uses, transfers, and protects your personal information when you use our websites, apps, and other services (collectively referred to as 'Services'). By using EasyToVPN, you consent to the collection and use of your information in accordance with this Privacy Policy.",
            text2: "We are committed to protecting your privacy and processing your personal information in accordance with applicable data protection laws, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA)."
          },
          informationCollected: {
            title: "2. Information We Collect",
            text: "We collect a minimal amount of information necessary to provide our Services. This may include:",
            accountInformation: {
              title: "Account Information",
              details: {
                email: "Email address for account creation, communication, and password recovery",
                name: "Name, Telegram ID, and username (if applicable)",
                paymentInfo: "Payment information (for paid subscriptions)",
                paymentAmount: "Payment amount, currency, and date",
                cardInfo: "Partial credit card information (last 4 digits)",
                country: "Country and postal code for tax purposes",
                payerEmail: "Payer's email address to prevent fraud",
                text: "All payments are processed by our trusted partners as described in our Terms of Service. These partners have access to payment data solely for processing payments and preventing fraud."
              }
            },
            communicationData: {
              title: "Communication Data",
              details: {
                email: "Email data when you contact us for support",
                deviceInfo: "Device information if necessary to resolve technical issues",
                chatData: "Limited data from online chat (OS, browser type)",
                text: "Usage data",
                services_and_features: "Information on which EasyToVPN services and features you use and how",
                browser_type: "Website access logs (browser type, OS, referral URLs) for site analysis and improvement",
                diagnostic_data: "Crash reports and diagnostic data for improving services"
              }
            },
            usageData: {
              title: "Usage Data",
              details: {
                servicesUsed: "Information on which EasyToVPN services and features you use and how",
                accessLogs: "Website access logs (browser type, OS, referral URLs) for site analysis and improvement",
                crashReports: "Crash reports and diagnostic data for improving services"
              }
            }
          },
          howWeUseYourInformation: {
            title: "3. How We Use Your Information",
            text: "We use your information for:",
            usageDetails: {
              manageAccount: "Managing your account",
              improveServices: "Providing, maintaining, and improving our Services",
              paymentProcessing: "Processing payments and preventing fraud",
              supportResponse: "Responding to support requests and resolving technical issues",
              usageAnalysis: "Analyzing usage trends and measuring advertising effectiveness",
              notifications: "Sending important account notifications and marketing communications"
            }
          },
          informationSharing: {
            title: "4. Information Sharing",
            text: "We do NOT sell, exchange, or transfer your personal information to third parties, except in the following cases:",
            sharingDetails: {
              trustedProviders: "Trusted service providers who assist with payment processing, customer support, email delivery, analytics, and advertising. These providers are bound by strict confidentiality agreements.",
              legalAuthorities: "Competent authorities if required by law or to protect our legitimate rights. We carefully review each request and provide only the minimum necessary data. We will only comply with requests from countries where the political regime is generally recognized as democratic, in accordance with international standards and indices.",
              groupCompanies: "Other companies within the EasyToVPN group for conducting day-to-day business operations and supporting our Services.",
              businessTransfer: "In the event of a merger, acquisition, or sale of assets, but even then, the acquired organization will be bound by this Privacy Policy."
            }
          },
          vpnServers: {
            title: "5. VPN Server Locations",
            text: "EasyToVPN offers servers in various countries around the world to provide optimal speed and connectivity for our users. While we strive to select jurisdictions with strong privacy protections, some of our servers may be located in countries with lower levels of democracy according to international standards and indices.",
            warning: "When connecting to servers in these locations, EasyToVPN will show a warning to inform you about potential risks. These risks include an increased likelihood that local governments may attempt to interfere with your internet traffic or request access to user data. EasyToVPN does not recommend using these servers if you are concerned about potential government interference or surveillance."
          },
          dataStorage: {
            title: "6. Data Storage",
            text: "We retain your personal information only as long as necessary to provide our Services and fulfill our legal obligations:",
            storageDetails: {
              paymentInfo: "Payment information is retained for 10 years from the last payment for tax and accounting purposes.",
              marketingConsent: "Consent for marketing communications lasts for 3 years after your subscription ends or until you withdraw it.",
              supportRequests: "Support request information is retained for 3 years after resolution, except for device data necessary to resolve service issues (retained for up to 6 months). When information is no longer needed, it is deleted or anonymized."
            }
          },
          userRights: {
            title: "7. Your Privacy Rights",
            text: "Depending on your location, you may have the right to:",
            rightsDetails: {
              access: "Access, correct, delete, or request a copy of your data",
              objection: "Object to certain data processing or limit it",
              withdrawConsent: "Withdraw consent for marketing communications",
              complaint: "File a complaint with your local data protection authority",
              complaint_info: "To exercise these rights, contact us using the methods provided below. We will respond to verified requests within 30 days."
            },
            contactInstruction: "To exercise these rights, contact us using the methods provided below. We will respond to verified requests within 30 days."
          },
          securityMeasures: {
            title: "8. Security Measures",
            text: "We take the security of your data seriously and use physical, technical, and administrative safeguards in accordance with industry standards, including:",
            securityDetails: {
              encryption: "Encryption of data at rest and in transit",
              firewalls: "Firewalls, access control, and intrusion detection systems",
              accessPolicies: "Strict employee access policies and security training",
              text: "In the unlikely event of a data breach, we will promptly notify affected users and take immediate action to protect our systems."
            },
            dataBreachAction: "In the unlikely event of a data breach, we will promptly notify affected users and take immediate action to protect our systems."
          },
          internationalDataTransfers: {
            title: "9. International Data Transfers",
            text: "Your information may be transferred and processed in countries other than your own, including the United States and the European Union. These countries may have different data protection laws.",
            protectionMeasures: "However, we ensure that appropriate safeguards are in place, such as standard contractual clauses approved by the European Commission, to protect your rights and freedoms.",
            consent: "By using EasyToVPN, you consent to the transfer of your data as described herein.",
            info: "However, we ensure that appropriate safeguards are in place, such as standard contractual clauses approved by the European Commission, to protect your rights and freedoms. By using EasyToVPN, you consent to the transfer of your data as described."
          },
          businessTransfer: {
            title: "10. Business Transfer",
            text: "If EasyToVPN is involved in a merger, acquisition, or sale of assets, your personal information may be transferred to the acquiring organization. However, it will continue to be governed by the promises made in the Privacy Policy in effect at the time of the transfer."
          },
          childrenPolicy: {
            title: "11. Children's Policy",
            text: "EasyToVPN is not intended for use by children under the age of 18. We do not knowingly collect personal information from minors. If we discover that we have data from a child under 18, we will promptly delete it."
          },
          policyUpdates: {
            title: "12. Policy Updates",
            text: "We may update our Privacy Policy from time to time to reflect changes in our practices or services. We will notify you of any significant changes via email or through a prominent notice on our websites before the changes take effect. Your continued use of EasyToVPN after changes take effect will constitute your acceptance of the revised Privacy Policy."
          },
          contact: {
            title: "13. Contact",
            text: "If you have any questions about this Privacy Policy or our data processing practices, please contact us using the following channels:",
            details: {
              email: "Email: easytovpn.com@gmail.com",
              telegram: "Telegram: https://t.me/vpnMyServiceBot",
              lastUpdated: "Last updated: 10.12.2023"
            }
          }
        },
        aboutUs: {
          paragraph1: 'Welcome to EasyToVPN! We are a team that believes in everyone\'s right to internet freedom and privacy protection. Our mission is to provide you with secure, fast and unrestricted internet access no matter where you are.',
          paragraph2: 'High Security: We use state-of-the-art encryption protocols to protect your data from hackers and prying eyes. Anonymity: Your online activities remain private thanks to a strict no-logs policy. Speed ​​and Stability: Our servers are optimized to provide high connection speeds and uninterrupted operation. Global Server Network: We provide access to servers in dozens of countries so you can enjoy content without restrictions. Ease of Use: Convenient apps for all your devices allow you to set up a VPN in seconds.',
          paragraph3: 'We believe that privacy is not a luxury, but a fundamental right. In a world where personal data is becoming the new currency, our mission is to help you maintain control over your digital life.',
          paragraph4: 'Join us and make the internet safe and free!',
          paragraph5: 'You can learn more about our services and values ​​on our website.',
          aboutUsTitle1: 'About Us',
          aboutUsTitle2: 'Why choose us:',
          aboutUsTitle3: 'Our philosophy',
        },
        passwordReminder: {
          title: 'Enter the email to which the new password will be sent',
          placeholder: 'Enter your email',
          send: 'Send'
        },
        mail: {
          mailOk: 'New password sent to your email',
          mailError: 'Incorrect or missing email address'
        },
        infoTariff: {
          tariff: 'Tariff',
          amount: 'Amount',
          date: 'Date of operation',
          finish_date: 'Finish date',
          noHistory: 'No renewal history'

        },
        selectOfMonths: 'Select the number of months:',
        tariff: 'tariff',
        period: 'Period',
        locations: 'Locations',
        selectFilter: {
          plan: 'Current tariff plan:',
          status: 'Status:',
          date: 'Date'
        },
        custom_pricing: 'Custom pricing',
        per_mounth: 'Per mounth',
        footer: {
          company: {
            title: "COMPANY:",
            item1: 'About Us',
            item2: 'Contacts',
            item3: 'Privacy policy',
            item4: 'Terms of use',
          },
          customerCenter: {
            title: "Customer Center:",
            item: 'Personal cabinet'
          },
          contacts: "Contacts:",
          ourSocialMedia: 'Our social media:'

        },
        advantages: {
          title: 'Why Choose Us?',
          speed: {
            title: 'SPEED',
            description: 'Speed from 500mbps'
          },
          fastSupport: {
            title: 'FAST SUPPORT',
            description: 'Experienced and fast technical support'
          },
          refund: {
            title: 'REFUND',
            description: 'Refunds are available within 30 days, if due to our fault you were unable to use the service'
          },
          customizing: {
            title: 'CUSTOMIZING',
            description: 'Help in setting up and using '
          }
        },
        descriptionVPN: {
          title: 'What\'s gonna get you',
          descriptionItem1: {
            title: 'Increased security - ',
            text: 'data encryption protects against hackers and information leaks, especially on public Wi-Fi networks.'
          },
          descriptionItem2: {
            title: 'Anonymity and privacy - ',
            text: 'hiding your real IP address and preventing online activity from being tracked.'
          },
          descriptionItem3: {
            title: 'Access to blocked resources - ',
            text: 'bypass geographic blocking and censorship (e.g., access to Netflix and blocked sites).'
          },
          descriptionItem4: {
            title: 'Anti-Surveillance Protection - ',
            text: 'prevent ad networks and ISPs from tracking your online activity.'
          }
        },

        country: {
          belgium: 'Belgium',
          czech: 'Czech',
          france: 'France',
          german: 'Germany',
          great_britain: 'Great Britain',
          greek: 'Greece',
          italy: 'Italy',
          netherlands: 'Netherlands',
          span: 'Spain',
          usa: 'USA',
        },
        settings: {
          setup1: 'Download the Outline Client from the App Store or Google Play, then connect with a unique access key. The client application can be installed on your computer, phone or tablet to surf the Internet on any device and share data securely.',
          setup2: 'Add a VPN activation token from your personal account',
          setup3: 'Connect your own VPN ',
          setup4: 'Check your new IP and use your favorite sites!',
        },
        continue_tarif: 'continue_tarif',
        edit: 'edit',
        continue: 'EXTEND THE TARIFF',
        month: 'month',
        payment_summ: "Enter the top-up amount in USDT",
        contactUs: "Contact Us",
        info: "Here is some information about our company and services...",
        contactTelegram: "Contact via Telegram",
        telegramLink: "For any questions or support, feel free to reach out to us on Telegram:",
        qrCodeText: "Scan this QR code to contact us on Telegram:",
        botName: "@vpnMyServiceBot",
        dashboard: 'User Dashboard',
        personalInfo: 'Personal Information',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        balance: 'Balance',
        selectPaymentMethod: 'Refill the balance',
        balanceReplenishment: 'Balance replenishment',
        payWithCrypto: 'Pay with Crypto',
        selectPlan: 'Available plans now',
        selectMonths: 'Your order',
        numberOfMonths: 'Number of Months',
        confirmSelection: 'Confirm Selection',
        paymentInformation: 'Payment Information',
        amount: 'Amount',
        limitTariff: 'Limit',
        days: 'Days left',
        location: 'Location',
        transactionId: 'Transaction ID',
        scanToPay: 'Scan to Pay',
        qrCodeAlt: 'QR Code for Payment',
        subscriptionConfirmation: 'Subscription Confirmation',
        successfullyActivated: 'You have successfully activated the',
        forMonths: 'for',
        months: 'months.',
        yourAccessCode: 'Your access code',
        saveAccessCode: 'Please save this access code for future reference.',
        insufficientFunds: 'Insufficient Funds',
        notEnoughBalance: 'You do not have enough balance to select this tariff.',
        tariffsHistory: 'Active Keys',
        service: 'Current tariff plan',
        startDate: 'Start Date',
        finishDate: 'Finish Date',
        vpnCode: 'VPN Code',
        status: 'Status',
        copy: 'Copied',
        noServiceHistory: 'No service history available.',
        showMore: 'Show more...',
        transactionsHistory: 'Transactions History',
        date: 'Date',
        noTransactionHistory: 'No transaction history available.',
        confirmAlert: {
          title: 'You selected the tariff',
          message: 'Are you sure you want to select the tariff to',
          months: 'months?',
          yes: 'Yes',
          no: 'No',
        },
        auth: {
          login: 'Login',
          signup: 'Signup',
          username: 'Username/Email',
          password: 'Password',
          confirmPassword: 'Confirm Password',
          noAccount: "Don't have an account? Sign up",
          haveAccount: 'Already have an account? Login',
          forgotPassword: 'Forgot password',
        },
        banner: {
          title: 'Protect Your Privacy with Our VPN',
          description: 'Secure your online activity and browse the internet safely with our reliable VPN service.',
          getStarted: 'Get started',
          buyNow: 'Buy now',
          country: 'Try'

        },
        pricing: {
          //title: 'Our Pricing',
          basicPlan: {
            title: 'Basic Plan',
            price: '$5/month',
            traffic: '500 GB Traffic',
            description1: 'Support full period of uses',
            description2: 'For basic home users. Ideal for browsing, streaming, and social media.'
          },
          familyPlan: {
            title: 'Family Plan',
            price: '$10/month',
            traffic: '2 TB Traffic',
            description1: 'Support full period of use',
            description2: 'For families. Perfect for multiple devices and active users.'
          },
          premiumPlan: {
            title: 'Premium Plan',
            price: '$25/month',
            traffic: 'Unlimited Traffic',
            description1: 'Support full period of use',
            description2: 'For heavy internet users. Unlimited access to the internet without restrictions.'
          },
          dedicatedServer: {
            title: 'Dedicated Server',
            price: 'Custom Pricing',
            traffic: 'Traffic on request',
            description1: 'Support full period of use',
            tg_conttact: 'https://t.me/vpnMyServiceBot',
            description2: 'For dedicated needs.'
          }
        },
        features: {
          title: 'Available locations',
          feature1: '(Possible custom location for your needs)',
          feature2: 'Flexible plans for any user',
          feature3: '24/7 customer support',
          feature4: 'Custom solutions for unique needs',
          feature5: 'Transparent terms without hidden fees'
        },
        // footer: {
        //     allRightsReserved: 'All rights reserved.',
        //     aboutUs: 'About Us',
        //     termsOfUse: 'Terms of Use',
        //     privacyPolicy: 'Privacy Policy',
        //     contact: 'Contact'
        // },
        profile: 'Profile',
        logout: 'Logout',
        login: 'Login',
        register: 'Register',
        changeLang: 'Change Language',
      },
    },
    ua: {
      translation: {
        transactionsStatu: {
          success: 'Успішно',
          pending: 'в очікуванні'
        },
        contactPage: {
          title: 'Зв\'яжіться з нами',
          follow: 'Слідкуйте за нами',
          address: 'Адреса:',
          sendMessage: 'Надіслати повідомлення',
          name: 'Ваше ім\'я:',
          email: 'Введіть свою електронну пошту',
          question: 'Яке питання Вас цікавить:',
          message: 'Повідомлення',
          enterMessage: 'Введіть своє повідомлення',
          sendBtn: 'Надіслати'

        },
        termsOfUse: {
          title: "Умови використання",
          introduction: {
            title: "1. Вступ",
            text: "Ми раді, що ви вирішили скористатися EasyToVPN! Ці Умови використання ('Умови') регулюють доступ до веб-сайтів, додатків і послуг EasyToVPN (далі 'Послуги'). Використовуючи наші веб-сайти або Послуги, ви погоджуєтесь дотримуватись цих Умов та нашої Політики конфіденційності. Якщо ви не згодні з цими Умовами, будь ласка, не використовуйте EasyToVPN.",
            text2: "Ми можемо періодично оновлювати ці Умови. Ми опублікуємо оновлені Умови на нашому веб-сайті та оновимо дату 'Останнє оновлення' внизу. Подальше використання EasyToVPN після внесення змін до Умов означає вашу згоду з новими Умовами. Якщо ви не погоджуєтесь із змінами, вам слід припинити використання наших Послуг."
          },
          ageRestrictionsAndRegistration: {
            title: "2. Вікові обмеження та реєстрація",
            text: "Вам має бути не менше 18 років, щоб користуватися EasyToVPN. Якщо вам менше 18 років, ви можете користуватися нашими Послугами лише під наглядом батьків або опікуна, які погоджуються з цими Умовами.",
            registration: "Щоб отримати доступ до певних функцій EasyToVPN, вам може знадобитися створити обліковий запис. Ви погоджуєтесь зберігати дані свого облікового запису у безпеці. Ви несете відповідальність за всі дії, здійснені у вашому обліковому записі. Якщо ви підозрюєте несанкціонований доступ до вашого облікового запису, негайно зв’яжіться з нами."
          },
          ourServices: {
            title: "3. Наші Послуги",
            text: "EasyToVPN надає послуги віртуальної приватної мережі (VPN), які шифрують ваш інтернет-трафік і приховують вашу IP-адресу. Під час підключення до EasyToVPN ваші дані передаються через наші захищені сервери в різних локаціях по всьому світу. Це допомагає захистити вашу конфіденційність та безпеку в Інтернеті.",
            policy: "EasyToVPN дотримується суворої політики відсутності логів. Ми не збираємо, не зберігаємо та не передаємо інформацію про вашу онлайн-активність під час використання VPN, включаючи відвідані сторінки та їхній вміст. Ми зберігаємо лише мінімальну інформацію, необхідну для роботи наших Послуг.",
            responsibility: "Ми категорично виступаємо проти будь-яких незаконних, злочинних або шахрайських дій, здійснених під час використання EasyToVPN. Оскільки ми не зберігаємо інформацію про ваші дії, ви несете повну відповідальність за такі дії."
          },
          acceptableUse: {
            title: "4. Допустиме використання",
            text: "Під час використання EasyToVPN ви повинні дотримуватися цих Умов і всіх чинних законів та правил. Забороняється:",
            restrictions: {
              illegalUse: "Використовувати EasyToVPN для будь-яких незаконних цілей або передачі шкідливого коду.",
              unauthorizedAccess: "Намагатися несанкціоновано отримати доступ до інших систем.",
              spam: "Використовувати наші Послуги для надсилання спаму або фішингових листів.",
              serviceInterference: "Перешкоджати роботі наших Послуг, наприклад, через надмірне навантаження серверів."
            },
            actions: "EasyToVPN залишає за собою право розслідувати порушення цих Умов і вживати відповідних заходів, включаючи припинення доступу до облікового запису без попередження та повідомлення відповідним органам."
          },
          fairUse: {
            title: "5. Справедливе використання",
            text: "Усі підписки EasyToVPN призначені для розумного особистого використання. Заборонено використовувати для комерційних цілей або надавати несанкціонований доступ третім особам."
          },
          subscriptionsAndPayments: {
            title: "6. Підписки та оплати",
            text: "EasyToVPN пропонує різні платні тарифні плани. Придбаваючи підписку на EasyToVPN, ви погоджуєтеся оплачувати відповідні збори та податки. Оплати обробляються нашими надійними партнерами."
          },
          refundPolicy: {
            title: "7. Політика повернення коштів",
            text: "Якщо ви не змогли повністю скористатися нашими платними Послугами через нашу провину, ми пропонуємо 30-денну гарантію повернення коштів. Зверніться до нашої служби підтримки за адресою easytovpn.com@gmail.com, щоб подати запит на повернення."
          },
          intellectualProperty: {
            title: "8. Інтелектуальна власність",
            text: "Веб-сайт EasyToVPN, додатки, послуги та пов'язаний з ними контент є виключною власністю EasyToVPN. Використовуючи наші Послуги, ви не отримуєте жодних прав власності на EasyToVPN."
          },
          disclaimerAndLimitationOfLiability: {
            title: "9. Відмова від відповідальності та обмеження відповідальності",
            text: "EasyToVPN надається 'як є' без будь-яких гарантій. Ми не гарантуємо безперебійної роботи наших Послуг. EasyToVPN не несе відповідальності за втрату даних або збитки, що виникають в результаті використання наших Послуг."
          },
          indemnity: {
            title: "10. Відшкодування збитків",
            text: "Ви погоджуєтесь відшкодовувати збитки, захищати та огороджувати EasyToVPN від будь-яких претензій, збитків і зобов'язань, що виникають через ваше використання наших Послуг або порушення цих Умов."
          },
          termination: {
            title: "11. Припинення дії",
            text: "EasyToVPN залишає за собою право призупинити або припинити ваш доступ до наших Послуг у будь-який час без попереднього повідомлення, якщо ви порушуєте ці Умови."
          },
          serviceModifications: {
            title: "12. Зміни у Послугах",
            text: "EasyToVPN залишає за собою право змінювати наші Послуги у будь-який час без попереднього повідомлення."
          },
          governingLanguage: {
            title: "13. Мова оригіналу",
            text: "Оригінальна мова цих Умов використання — англійська. У разі розбіжностей між перекладом і англійською версією, переважну силу має англійська версія."
          },
          contact: {
            title: "14. Контакти",
            text: "Якщо у вас є питання, зауваження або пропозиції щодо цих Умов або EasyToVPN, будь ласка, зв'яжіться з нашою службою підтримки:",
            details: {
              email: "Електронна пошта: easytovpn.com@gmail.com",
              chat: "Чат на нашому сайті: https://www.easytovpn.com",
              lastUpdated: "Останнє оновлення: 10.12.2023"
            }
          }
        },
        privacyPolicy: {
          title: 'Політика конфіденційності',
          introduction: {
            title: "1. Вступ",
            text: "У цій Політиці конфіденційності пояснюється, як EasyToVPN ('EasyToVPN', 'ми', 'нас' або 'наш') збирає, використовує, передає та захищає вашу особисту інформацію, коли ви користуєтесь нашими веб-сайтами, додатками та іншими сервісами (спільно іменованими 'Сервіси'). Використовуючи EasyToVPN, ви погоджуєтесь на збір та використання вашої інформації відповідно до цієї Політики конфіденційності.",
            text2: "Ми прагнемо захищати вашу конфіденційність та обробляти вашу особисту інформацію лише відповідно до застосовних законів про захист даних, включаючи Загальний регламент ЄС із захисту даних (GDPR) та Закон про захист конфіденційності споживачів у Каліфорнії (CCPA)."
          },
          informationCollected: {
            title: "2. Інформація, яку ми збираємо",
            text: "Ми збираємо мінімальний обсяг інформації, необхідний для надання наших Сервісів. Це може включати:",
            accountInformation: {
              title: "Інформація про обліковий запис",
              details: {
                email: "Адреса електронної пошти для створення облікового запису, зв'язку та відновлення забутих паролів",
                name: "Ім'я, ідентифікатор Telegram та ім'я користувача (якщо застосовно)",
                paymentInfo: "Платіжна інформація (для платних підписок)",
                paymentAmount: "Сума платежу, валюта та дата",
                cardInfo: "Часткова інформація про кредитну картку (останні 4 цифри)",
                country: "Країна та поштовий індекс для податкових цілей",
                payerEmail: "Адреса електронної пошти платника для запобігання шахрайству",
                text: 'Все платежи обрабатываются нашими доверенными партнерами, как указано в наших Условиях обслуживания. Эти партнеры имеют доступ к платежным данным исключительно для обработки платежей и предотвращения мошенничества.'
              }
            },
            communicationData: {
              title: "Дані для комунікації",
              details: {
                email: "Дані електронної пошти, коли ви звертаєтесь до нас за підтримкою",
                deviceInfo: "Інформація про пристрій, якщо це необхідно для вирішення технічних проблем",
                chatData: "Обмежені дані з онлайн-чату (ОС, тип браузера)",
                text: "Дані про використання",
                services_and_features: "Інформація про те, які сервіси та функції EasyToVPN ви використовуєте і як",
                browser_type: 'Журнали доступу до веб-сайту (тип браузера, ОС, реферальні URL) для аналізу та покращення нашого сайту',
                diagnostic_data: 'Отчеты о сбоях и диагностические данные для улучшения сервисов'
              }
            },
            usageData: {
              title: "Дані про використання",
              details: {
                servicesUsed: "Інформація про те, які сервіси та функції EasyToVPN ви використовуєте і як",
                accessLogs: "Журнали доступу до вебсайту (тип браузера, ОС, реферальні URL) для аналізу та покращення нашого сайту",
                crashReports: "Звіти про збої та діагностичні дані для покращення сервісів"
              }
            }
          },
          howWeUseYourInformation: {
            title: "3. Як ми використовуємо вашу інформацію",
            text: "Ми використовуємо вашу інформацію для:",
            usageDetails: {
              manageAccount: "Управління вашим обліковим записом",
              improveServices: "Надання, обслуговування та покращення наших Сервісів",
              paymentProcessing: "Обробки платежів та запобігання шахрайству",
              supportResponse: "Відповідей на запити в службу підтримки та вирішення технічних проблем",
              usageAnalysis: "Аналізу тенденцій використання та вимірювання ефективності реклами",
              notifications: "Надсилання важливих повідомлень про обліковий запис та маркетингових повідомлень"
            }
          },
          informationSharing: {
            title: "4. Передача інформації",
            text: "Ми НЕ продаємо, не обмінюємо та не передаємо вашу особисту інформацію третім особам, за винятком випадків, описаних нижче:",
            sharingDetails: {
              trustedProviders: "Надійним постачальникам послуг, які допомагають з обробкою платежів, підтримкою клієнтів, доставкою електронної пошти, аналітикою та рекламою. Ці постачальники пов'язані строгими угодами про конфіденційність.",
              legalAuthorities: "Компетентним органам, якщо це вимагається законом або для захисту наших законних прав. Ми ретельно розглядаємо кожен запит і надаємо лише мінімально необхідні дані. Ми будемо виконувати лише запити з країн, де політичний режим загальновизнано вважається демократичним, згідно з міжнародними стандартами та індексами.",
              groupCompanies: "Іншим компаніям групи EasyToVPN для ведення щоденних бізнес-операцій та підтримки наших Сервісів.",
              businessTransfer: "У разі злиття, поглинання або продажу активів, але навіть тоді придбана організація буде пов'язана цією Політикою конфіденційності."
            }
          },
          vpnServers: {
            title: "5. Розташування VPN-серверів",
            text: "EasyToVPN пропонує сервери в різних країнах світу, щоб забезпечити нашим користувачам оптимальну швидкість та підключення. Хоча ми прагнемо вибирати юрисдикції з надійним захистом конфіденційності, деякі наші сервери можуть бути розташовані в країнах з нижчим рівнем демократії згідно з міжнародними стандартами та індексами.",
            warning: "При підключенні до серверів у цих місцях EasyToVPN покаже попередження, щоб інформувати вас про потенційні ризики. Ці ризики включають підвищену ймовірність того, що місцеві уряди можуть спробувати втрутитися у ваш інтернет-трафік або запросити доступ до даних користувачів. EasyToVPN не рекомендує використовувати ці сервери, якщо ви стурбовані можливим втручанням чи стеженням з боку уряду."
          },
          dataStorage: {
            title: "6. Зберігання даних",
            text: "Ми зберігаємо вашу особисту інформацію тільки до тих пір, поки це необхідно для надання наших Сервісів та виконання наших юридичних зобов'язань:",
            storageDetails: {
              paymentInfo: "Платіжна інформація зберігається протягом 10 років з моменту останнього платежу для податкових та бухгалтерських цілей.",
              marketingConsent: "Згода на маркетингові комунікації діє протягом 3 років після закінчення вашої підписки або до того часу, поки ви не відмовитесь від неї.",
              supportRequests: "Інформація про запити в службу підтримки зберігається протягом 3 років після їх вирішення, за винятком даних про ваші пристрої, необхідних для вирішення проблем із сервісом (зберігаються максимум 6 місяців). Коли інформація більше не потрібна, вона видаляється чи анонімізується."
            }
          },
          userRights: {
            title: "7. Ваші права на конфіденційність",
            text: "Залежно від вашого місцезнаходження, ви можете мати право:",
            rightsDetails: {
              access: "Отримати доступ, виправити, видалити або запитати копію ваших даних",
              objection: "Заперечувати проти певної обробки даних або обмежити її",
              withdrawConsent: "Відкликати згоду на маркетингові комунікації",
              complaint: "Подати скаргу до місцевого органу захисту даних",
              complaint_info: 'Щоб скористатися цими правами, зв\'яжіться з нами, використовуючи наведені нижче методи. Ми відповімо на перевірені запити протягом 30 днів.'
            },
            contactInstruction: "Щоб скористатись цими правами, зв'яжіться з нами, використовуючи методи, вказані нижче. Ми відповімо на перевірені запити протягом 30 днів."
          },
          securityMeasures: {
            title: "8. Заходи безпеки",
            text: "Ми дуже серйозно ставимося до безпеки ваших даних і використовуємо фізичні, технічні та адміністративні заходи захисту відповідно до галузевих стандартів, включаючи:",
            securityDetails: {
              encryption: "Шифрування даних в стані спокою та при передачі",
              firewalls: "Брандмауери, контроль доступу та системи виявлення вторгнень",
              accessPolicies: "Строгі політики доступу співробітників та навчання правилам безпеки",
              text: 'У малоймовірному випадку витоку даних ми оперативно повідомимо порушених користувачів і почнемо негайні дії для захисту наших систем.'
            },
            dataBreachAction: "У малоймовірному випадку витоку даних ми оперативно повідомимо постраждалих користувачів і вживемо негайних заходів для захисту наших систем."
          },
          internationalDataTransfers: {
            title: "9. Міжнародні передачі даних",
            text: "Ваша інформація може передаватися та оброблятися в країнах, відмінних від вашої власної, включаючи США та Європейський Союз. У цих країнах можуть діяти інші закони про захист даних.",
            protectionMeasures: "Однак ми забезпечуємо наявність відповідних заходів захисту, таких як стандартні договірні положення, схвалені Європейською комісією, для захисту ваших прав та свобод.",
            consent: "Використовуючи EasyToVPN, ви даєте згоду на передачу ваших даних відповідно до цього опису.",
            info: 'Проте ми забезпечуємо наявність відповідних заходів захисту, таких як стандартні договірні положення, схвалені Європейською комісією, для захисту ваших прав та свобод. Використовуючи EasyToVPN, ви погоджуєтеся на передачу ваших даних відповідно до опису.'
          },
          businessTransfer: {
            title: "10. Передача бізнесу",
            text: "Якщо EasyToVPN братиме участь у злитті, поглинанні або продажу активів, ваша особиста інформація може бути передана придбаної організації. Тим не менш, вона буде й надалі підпорядковуватися обіцянкам, наданим у Політиці конфіденційності, яка була чинною на момент передачі."
          },
          childrenPolicy: {
            title: "11. Політика щодо дітей",
            text: "EasyToVPN не призначений для використання дітьми до 18 років. Ми свідомо не збираємо особисту інформацію неповнолітніх. Якщо ми виявимо, що у нас є дані дитини віком до 18 років, ми негайно їх видалимо."
          },
          policyUpdates: {
            title: "12. Оновлення цієї політики",
            text: "Ми можемо час від часу оновлювати нашу Політику конфіденційності, щоб відобразити зміни в наших практиках або послугах. Ми повідомимо вас про суттєві зміни електронною поштою або через помітне повідомлення на наших вебсайтах до вступу змін в силу. Ваше подальше використання EasyToVPN після змін означає прийняття переглянутої Політики конфіденційності."
          },
          contact: {
            title: "13. Контакти",
            text: "Якщо у вас є питання щодо цієї Політики конфіденційності або наших методів обробки даних, будь ласка, зв'яжіться з нами за допомогою наступних каналів зв'язку:",
            details: {
              email: "Електронна пошта: easytovpn.com@gmail.com",
              telegram: "Telegram: https://t.me/vpnMyServiceBot",
              lastUpdated: "Останнє оновлення: 10.12.2023"
            }
          }

        },
        aboutUs: {
          paragraph1: 'Ласкаво просимо до EasyToVPN! Ми є командою, яка вірить у право кожної людини на свободу інтернету та захист конфіденційності. Наша місія – забезпечити вам безпечний, швидкий та необмежений доступ до інтернету незалежно від того, де ви знаходитесь.',
          paragraph2: 'Високий рівень безпеки: Ми використовуємо сучасні протоколи шифрування, щоб захистити ваші дані від хакерів та сторонніх очей. Анонімність: Ваші дії в інтернеті залишаються конфіденційними завдяки суворій політиці відсутності журналів (no-logs policy). Швидкість та стабільність: Наші сервери оптимізовані для забезпечення високої швидкості з\'єднання та безперервної роботи. Глобальна мережа серверів: Ми надаємо доступ до серверів у десятках країн, щоб ви могли насолоджуватися контентом без обмежень. Простота використання: Зручні програми для всіх пристроїв дозволяють налаштувати VPN за лічені секунди.',
          paragraph3: 'Ми переконані, що приватність це не розкіш, а базове право. У світі, де персональні дані стають новою валютою, наше завдання – допомогти вам зберегти контроль над своїм цифровим життям.',
          paragraph4: 'Приєднуйтесь до нас та зробіть інтернет безпечним та вільним!',
          paragraph5: 'Детальніше про наші послуги та цінності ви можете дізнатися на нашому сайті.',
          aboutUsTitle1: 'Про нас',
          aboutUsTitle2: 'Чому вибирають нас:',
          aboutUsTitle3: 'Наша філософія',
        },
        passwordReminder: {
          title: 'Введіть адресу електронної пошти, на яку буде надіслано новий пароль.',
          placeholder: 'Введіть свою адресу електронної пошти',
          send: 'Надіслати'
        },
        mail: {
          mailOk: 'Новий пароль надіслано на ваш email',
          mailError: 'Неправильна або відсутня електронна адреса'
        },
        infoTariff: {
          tariff: 'Тариф',
          amount: 'Сума',
          date: 'Дата операції',
          finish_date: 'Дата завершення',
          noHistory: 'Немає історії продовжень'

        },
        selectOfMonths: 'Виберіть кількість місяців:',
        period: 'Період',
        tariff: 'Тариф',
        locations: 'Локація',
        selectFilter: {
          plan: 'Актуальний тарифний план:',
          status: 'Статус:',
          date: 'Дата'
        },
        custom_pricing: 'За домовленістю',
        per_mounth: 'За місяць',
        footer: {
          company: {
            title: "КОМПАНІЯ:",
            item1: 'Про нас',
            item2: 'Контакти',
            item3: 'Політика конфіденційності',
            item4: 'Умови використання',
          },
          customerCenter: {
            title: "Центр обслуговування клієнтів:",
            item: 'Особистий кабінет'
          },
          contacts: "Контакти:",
          ourSocialMedia: 'Ми в соціальних мережах:'
        },

        advantages: {
          title: 'Чому обирають нас?',
          speed: {
            title: 'ШВИДКІСТЬ',
            description: 'Швидкість від 500 Мбіт/с'
          },
          fastSupport: {
            title: 'ШВИДКА ПІДТРИМКА',
            description: 'Досвідчена та швидка технічна підтримка'
          },
          refund: {
            title: 'ПОВЕРНЕННЯ',
            description: 'Повернення доступне протягом 30 днів, якщо ж через нашу провину ви не змогли користуватися послугою'
          },
          customizing: {
            title: 'НАЛАШТУВАННЯ',
            description: 'Допомога в налаштуванні та використанні'
          }
        },
        descriptionVPN: {
          title: 'Що вам це дає',
          descriptionItem1: {
            title: 'Підвищена безпека - ',
            text: 'шифрування даних захищає від хакерів і витоку інформації, особливо в мережах публічного Wi-Fi.'
          },
          descriptionItem2: {
            title: 'Анонімність та конфіденційність - ',
            text: 'приховування вашої реальної IP-адреси і запобігання відстеженню активності в Інтернеті.'
          },
          descriptionItem3: {
            title: 'Доступ до заблокованих ресурсів - ',
            text: 'обхід географічних блокувань і цензури (наприклад, доступ до Netflix та заблокованих сайтів).'
          },
          descriptionItem4: {
            title: 'Захист від спостереження - ',
            text: 'запобігання стеженню за вашою активністю рекламними мережами та інтернет-провайдерами.'
          },
          descriptionFooter: 'Чому обирають нас?'
        },
        country: {
          belgium: 'Бельгія',
          czech: 'Чехія',
          france: 'Франція',
          german: 'Німеччина',
          great_britain: 'Велика Британія',
          greek: 'Греція',
          italy: 'Італія',
          netherlands: 'Нідерланди',
          span: 'Іспанія',
          usa: 'США',
        },
        settings: {
          setup1: 'Завантажте клієнт Outline з App Store або Google Play, потім підключіться за допомогою унікального ключа доступу. Клієнтська програма може бути встановлена на вашому комп\'ютері, телефоні або планшеті для безпечного серфінгу в Інтернеті та обміну даними.',
          setup2: 'Додайте токен активації VPN з вашого особистого облікового запису',
          setup3: 'Підключіть власний VPN',
          setup4: 'Перевірте свій новий IP та користуйтеся улюбленими сайтами!',
        },

        continue: 'ПРОДОВЖИТИ ТАРИФ',
        month: 'місяць',
        payment_summ: "Введіть сумму попвнення в USDT",
        contactUs: "Зв'яжіться з нами",
        info: "Ось деяка інформація про нашу компанію та послуги...",
        contactTelegram: "Зв'яжіться через Telegram",
        telegramLink: "Якщо у вас є питання або ви потребуєте підтримки, зв'яжіться з нами через Telegram:",
        qrCodeText: "Скануйте цей QR-код, щоб зв'язатися з нами в Telegram:",
        botName: "@vpnMyServiceBot",
        dashboard: 'Панель користувача',
        personalInfo: 'Особиста інформація',
        name: 'Ім\'я',
        email: 'Електронна пошта',
        phone: 'Телефон',
        address: 'Адреса',
        balance: 'Баланс',
        selectPaymentMethod: 'Поповнити баланс',
        balanceReplenishment: 'Поповнення балансу',
        payWithCrypto: 'Оплатити криптовалютою',
        selectPlan: 'Доступні плани зараз',
        selectMonths: 'Твоє замовлення',
        numberOfMonths: 'Кількість місяців',
        confirmSelection: 'Підтвердити вибір',
        paymentInformation: 'Інформація про платіж',
        amount: 'Сума',
        limitTariff: 'Ліміт',
        days: 'Днів залишилось',
        location: 'Розташування',
        transactionId: 'Ідентифікатор транзакції',
        scanToPay: 'Скануйте для оплати',
        qrCodeAlt: 'QR код для оплати',
        subscriptionConfirmation: 'Підтвердження підписки',
        successfullyActivated: 'Ви успішно активували тариф',
        forMonths: 'на',
        months: 'місяців.',
        yourAccessCode: 'Ваш код доступу',
        saveAccessCode: 'Будь ласка, збережіть цей код для майбутнього використання.',
        insufficientFunds: 'Недостатньо коштів',
        notEnoughBalance: 'У вас недостатньо балансу для вибору цього тарифу.',
        tariffsHistory: 'Активні ключі',
        service: 'Актуальний тарифний план:',
        startDate: 'Дата початку',
        finishDate: 'Дата закінчення',
        vpnCode: 'VPN код',
        status: 'Статус',
        copy: 'Скопійовано',
        noServiceHistory: 'Історія послуг недоступна.',
        showMore: 'Показати більше...',
        transactionsHistory: 'Історія транзакцій',
        date: 'Дата',
        noTransactionHistory: 'Історія транзакцій недоступна.',
        confirmAlert: {
          title: 'Ви вибрали тариф',
          message: 'Ви впевнені, що бажаєте підключити тариф на',
          months: 'місяців?',
          yes: 'Так',
          no: 'Ні',
        },

        auth: {
          login: 'Увійти',
          signup: 'Реєстрація',
          username: 'Логін/Електронна пошта',
          password: 'Пароль',
          confirmPassword: 'Підтвердження пароля',
          noAccount: "Немає облікового запису? Зареєструйтесь",
          haveAccount: 'Вже є обліковий запис? Увійти',
          forgotPassword: 'Забув пароль',

        },
        banner: {
          title: 'Захистіть свою приватність за допомогою нашого VPN',
          description: 'Захистіть свою онлайн-активність та безпечно переглядайте інтернет за допомогою нашого надійного VPN-сервісу.',
          getStarted: 'Почати',
          buyNow: 'Підключити',
          country: 'Спробувати'
        },
        pricing: {
          title: 'Наші ціни',
          basicPlan: {
            title: 'Базовий план',
            price: '5$/місяць',
            traffic: '500 ГБ трафіку',
            description1: 'Для базових домашніх користувачів',
            description2: 'Ідеально для перегляду, стрімінгу та соціальних мереж.'
          },
          familyPlan: {
            title: 'Сімейний план',
            price: '10$/місяць',
            traffic: '2 ТБ трафіку',
            description1: 'Для сім\'ї',
            description2: 'Ідеально для декількох пристроїв та активних користувачів.'
          },
          premiumPlan: {
            title: 'Преміум план',
            price: '25$/місяць',
            traffic: 'Необмежений трафік',
            description1: 'Для активних інтернет-користувачів',
            description2: 'Необмежений доступ до інтернету без обмежень.'
          },
          dedicatedServer: {
            title: 'Виділений сервер',
            price: 'Індивідуальне ціноутворення',
            traffic: 'Трафік на вимогу',
            description1: 'Підтримка повного періоду використання',
            tg_conttact: "https://t.me/vpnMyServiceBot",
            description2: "Персональний сапорт"

          }
        },
        features: {
          title: 'Чому обирають нас?',
          feature1: 'Надійне та стабільне з\'єднання',
          feature2: 'Гнучкі плани для будь-якого користувача',
          feature3: 'Підтримка 24/7',
          feature4: 'Індивідуальні рішення для унікальних потреб',
          feature5: 'Прозорі умови без прихованих платежів'
        },
        // footer: {
        //     allRightsReserved: 'Всі права захищені.',
        //     aboutUs: 'Про нас',
        //     termsOfUse: 'Умови використання',
        //     privacyPolicy: 'Політика конфіденційності',
        //     contact: 'Контакти'
        // },
        profile: 'Профіль',
        logout: 'Вихід',
        login: 'Увійти',
        register: 'Реєстрація',
        changeLang: 'Змінити мову',
      },
    },
    ru: {
      translation: {
        transactionsStatu: {
          success: 'успешнo',
          pending: 'в ожидании'
        },
        contactPage: {
          title: 'Связаться с нами',
          follow: 'Подписывайтесь на нас',
          address: 'Адрес:',
          sendMessage: 'Отправить сообщение',
          name: 'Ваше имя:',
          email: 'Введите свой адрес электронной почты',
          question: 'Какой вопрос вас интересует:',
          message: 'Сообщение',
          enterMessage: 'Введите свое сообщение',
          sendBtn: 'Отправлять'

        },
        termsOfUse: {
          title: "Условия использования",
          introduction: {
            title: "1. Введение",
            text: "Мы рады, что вы решили воспользоваться EasyToVPN! Настоящие Условия использования ('Условия') регламентируют доступ и использование веб-сайтов, приложений и сервисов EasyToVPN (совместно именуемые 'Услуги'). Получая доступ к нашим сайтам или используя наши Услуги, вы соглашаетесь соблюдать эти Условия и нашу Политику конфиденциальности. Если вы не согласны с этими Условиями, пожалуйста, не используйте EasyToVPN.",
            text2: "Мы можем периодически обновлять эти Условия. Мы опубликуем пересмотренные Условия на нашем сайте и обновим дату 'Последнее обновление' внизу. Ваше дальнейшее использование EasyToVPN после внесения любых изменений в Условия означает ваше согласие с новыми Условиями. Если вы не согласны с изменениями, вы должны прекратить использование наших Услуг."
          },
          ageRestrictionsAndRegistration: {
            title: "2. Возрастные ограничения и регистрация",
            text: "Вы должны быть не младше 18 лет, чтобы использовать EasyToVPN. Если вам еще нет 18, вы можете пользоваться нашими Услугами только под присмотром родителя или опекуна, который согласен соблюдать эти Условия.",
            registration: "Чтобы получить доступ к определенным функциям EasyToVPN, вам может потребоваться создать аккаунт. Вы соглашаетесь хранить данные своей учетной записи в безопасности. Вы несете ответственность за все действия, совершенные в вашем аккаунте. Если вы подозреваете, что кто-то получил доступ к вашему аккаунту, немедленно свяжитесь с нами."
          },
          ourServices: {
            title: "3. Наши Услуги",
            text: "EasyToVPN предоставляет услуги виртуальной частной сети (VPN), которые шифруют ваш интернет-трафик и скрывают ваш IP-адрес. Когда вы подключаетесь к EasyToVPN, ваши данные направляются через наши защищенные серверы в различных локациях по всему миру. Это помогает защитить вашу конфиденциальность и безопасность в Интернете.",
            policy: "EasyToVPN придерживается строгой политики отсутствия логов. Мы не собираем, не храним и не передаем никакой информации о вашей онлайн-активности при подключении к VPN, включая посещенные страницы и их содержание. Мы храним только минимальный объем информации, необходимый для работы наших Услуг.",
            responsibility: "Мы категорически против любых незаконных, преступных или мошеннических действий, совершаемых при использовании Услуг EasyToVPN. Поскольку мы не храним информацию о ваших действиях при использовании Услуг, вы несете полную ответственность за такие действия."
          },
          acceptableUse: {
            title: "4. Допустимое использование",
            text: "При использовании EasyToVPN вы должны соблюдать настоящие Условия и все применимые законы и нормативные акты. Запрещается:",
            restrictions: {
              illegalUse: "Использовать EasyToVPN для любых незаконных целей или передачи вредоносного кода.",
              unauthorizedAccess: "Попытки несанкционированного доступа к другим системам.",
              spam: "Использовать наши Услуги для пересылки спама или фишинговых писем.",
              serviceInterference: "Вмешиваться в работу наших Услуг, например, чрезмерной загрузкой серверов."
            },
            actions: "EasyToVPN оставляет за собой право расследовать и принимать соответствующие правовые меры против любого, кто нарушает эти условия, включая прекращение их учетной записи без уведомления и сообщение об их действиях соответствующим органам."
          },
          fairUse: {
            title: "5. Добросовестное использование",
            text: "Все подписки EasyToVPN предназначены для разумного личного использования. Недопустимо использование в коммерческих целях или для несанкционированного доступа третьих лиц."
          },
          subscriptionsAndPayments: {
            title: "6. Подписки и платежи",
            text: "EasyToVPN предлагает различные платные тарифные планы. Приобретая подписку EasyToVPN, вы соглашаетесь оплачивать применимые сборы и налоги. Оплата обрабатывается нашими доверенными партнерами."
          },
          refundPolicy: {
            title: "7. Возврат средств",
            text: "Если вы полностью не смогли воспользоваться нашими платными Услугами по нашей вине, мы предлагаем 30-дневную гарантию возврата денег. Свяжитесь с нашей службой поддержки по адресу easytovpn.com@gmail.com для запроса возврата."
          },
          intellectualProperty: {
            title: "8. Интеллектуальная собственность",
            text: "Веб-сайт EasyToVPN, приложения, сервисы и связанный с ними контент являются исключительной собственностью EasyToVPN. Используя наши Услуги, вы не приобретаете никаких прав собственности на EasyToVPN."
          },
          disclaimerAndLimitationOfLiability: {
            title: "9. Отказ от ответственности и ограничение ответственности",
            text: "EasyToVPN предоставляется 'как есть' без гарантий. Мы не гарантируем, что наши Услуги будут работать без сбоев. EasyToVPN не несет ответственности за любые убытки или потери данных, возникающие в результате использования наших Услуг."
          },
          indemnity: {
            title: "10. Возмещение убытков",
            text: "Вы соглашаетесь возмещать убытки, защищать и ограждать EasyToVPN от любых претензий, убытков и обязательств, возникающих из вашего использования наших Услуг или нарушения этих Условий."
          },
          termination: {
            title: "11. Прекращение действия",
            text: "EasyToVPN оставляет за собой право приостановить или прекратить ваш доступ к нашим Услугам в любое время без предварительного уведомления, если вы нарушите настоящие Условия."
          },
          serviceModifications: {
            title: "12. Изменения Услуг",
            text: "EasyToVPN оставляет за собой право изменять наши Услуги в любое время без предварительного уведомления."
          },
          governingLanguage: {
            title: "13. Преобладающий язык",
            text: "Оригинальный язык настоящих Условий использования — английский. В случае расхождений между переводом и английской версией, английская версия имеет преимущественную силу."
          },
          contact: {
            title: "14. Контакты",
            text: "Если у вас есть какие-либо вопросы, проблемы или отзывы об этих Условиях или EasyToVPN, пожалуйста, свяжитесь с нашей службой поддержки:",
            details: {
              email: "Электронная почта: easytovpn.com@gmail.com",
              chat: "Чат на нашем сайте: https://www.easytovpn.com",
              lastUpdated: "Последнее обновление: 10.12.2023"
            }
          }
        },
        privacyPolicy: {
          title: 'Политика конфиденциальности',
          introduction: {
            title: "1. Введение",
            text: "В данной Политике конфиденциальности объясняется, как EasyToVPN ('EasyToVPN', 'мы', 'нас' или 'наш') собирает, использует, передает и защищает вашу личную информацию при использовании наших веб-сайтов, приложений и других сервисов (вместе именуемых 'Сервисы'). Используя EasyToVPN, вы соглашаетесь на сбор и использование вашей информации в соответствии с этой Политикой конфиденциальности.",
            text2: "Мы стремимся защищать вашу конфиденциальность и обрабатывать вашу личную информацию только в соответствии с применимыми законами о защите данных, включая Общий регламент ЕС по защите данных (GDPR) и Закон о защите конфиденциальности потребителей в Калифорнии (CCPA)."
          },
          informationCollected: {
            title: "2. Информация, которую мы собираем",
            text: "Мы собираем минимальное количество информации, необходимое для предоставления наших Сервисов. Это может включать:",
            accountInformation: {
              title: "Информация об аккаунте",
              details: {
                email: "Адрес электронной почты для создания аккаунта, связи и восстановления забытых паролей",
                name: "Имя, идентификатор Telegram и имя пользователя (если применимо)",
                paymentInfo: "Платежная информация (для платных подписок)",
                paymentAmount: "Сумма платежа, валюта и дата",
                cardInfo: "Частичная информация о кредитной карте (последние 4 цифры)",
                country: "Страна и почтовый индекс для налоговых целей",
                payerEmail: "Адрес электронной почты плательщика для предотвращения мошенничества",
                text: 'Все платежи обрабатываются нашими доверенными партнерами, как указано в наших Условиях обслуживания. Эти партнеры имеют доступ к платежным данным исключительно для обработки платежей и предотвращения мошенничества.'
              }
            },
            communicationData: {
              title: "Данные для коммуникации",
              details: {
                email: "Данные электронной почты, когда вы обращаетесь к нам за поддержкой",
                deviceInfo: "Информация о устройстве, если это необходимо для решения технических проблем",
                chatData: "Ограниченные данные из онлайн-чата (ОС, тип браузера)",
                text: "Данные о использовании",
                services_and_features: "Информация о том, какие сервисы и функции EasyToVPN вы используете и как",
                browser_type: 'Журналы доступа к веб-сайту (тип браузера, ОС, реферальные URL) для анализа и улучшения нашего сайта',
                diagnostic_data: 'Отчеты о сбоях и диагностические данные для улучшения сервисов'
              }
            },
            usageData: {
              title: "Данные о использовании",
              details: {
                servicesUsed: "Информация о том, какие сервисы и функции EasyToVPN вы используете и как",
                accessLogs: "Журналы доступа к веб-сайту (тип браузера, ОС, реферальные URL) для анализа и улучшения нашего сайта",
                crashReports: "Отчеты о сбоях и диагностические данные для улучшения сервисов"
              }
            }
          },
          howWeUseYourInformation: {
            title: "3. Как мы используем вашу информацию",
            text: "Мы используем вашу информацию для:",
            usageDetails: {
              manageAccount: "Управления вашим аккаунтом",
              improveServices: "Предоставления, обслуживания и улучшения наших Сервисов",
              paymentProcessing: "Обработки платежей и предотвращения мошенничества",
              supportResponse: "Ответов на запросы в службу поддержки и решения технических проблем",
              usageAnalysis: "Анализа тенденций использования и измерения эффективности рекламы",
              notifications: "Отправки важных уведомлений об аккаунте и маркетинговых уведомлений"
            }
          },
          informationSharing: {
            title: "4. Передача информации",
            text: "Мы НЕ продаем, не обмениваем и не передаем вашу личную информацию третьим лицам, за исключением случаев, описанных ниже:",
            sharingDetails: {
              trustedProviders: "Надежным поставщикам услуг, которые помогают с обработкой платежей, поддержкой клиентов, доставкой электронной почты, аналитикой и рекламой. Эти поставщики связаны строгими соглашениями о конфиденциальности.",
              legalAuthorities: "Компетентным органам, если это требуется законом или для защиты наших законных прав. Мы тщательно рассматриваем каждый запрос и предоставляем только минимально необходимые данные. Мы будем выполнять только запросы из стран, где политический режим общепризнан как демократичный, согласно международным стандартам и индексам.",
              groupCompanies: "Другим компаниям группы EasyToVPN для ведения повседневных бизнес-операций и поддержки наших Сервисов.",
              businessTransfer: "В случае слияния, поглощения или продажи активов, но даже тогда приобретенная организация будет связана этой Политикой конфиденциальности."
            }
          },
          vpnServers: {
            title: "5. Местоположение VPN-серверов",
            text: "EasyToVPN предоставляет серверы в различных странах мира для обеспечения нашим пользователям оптимальной скорости и подключения. Хотя мы стремимся выбирать юрисдикции с надежной защитой конфиденциальности, некоторые наши серверы могут находиться в странах с более низким уровнем демократии согласно международным стандартам и индексам.",
            warning: "При подключении к серверам в этих местах EasyToVPN покажет предупреждение, чтобы информировать вас о потенциальных рисках. Эти риски включают повышенную вероятность того, что местные правительства могут попытаться вмешаться в ваш интернет-трафик или запросить доступ к данным пользователей. EasyToVPN не рекомендует использовать эти серверы, если вы беспокоитесь о возможном вмешательстве или слежке со стороны правительства."
          },
          dataStorage: {
            title: "6. Хранение данных",
            text: "Мы храним вашу личную информацию только до тех пор, пока это необходимо для предоставления наших Сервисов и выполнения наших юридических обязательств:",
            storageDetails: {
              paymentInfo: "Платежная информация хранится в течение 10 лет с момента последнего платежа для налоговых и бухгалтерских целей.",
              marketingConsent: "Согласие на маркетинговые коммуникации действует в течение 3 лет после окончания вашей подписки или до тех пор, пока вы не откажетесь от него.",
              supportRequests: "Информация о запросах в службу поддержки хранится в течение 3 лет после их решения, за исключением данных о ваших устройствах, необходимых для решения проблем с сервисом (хранятся максимум 6 месяцев). Когда информация больше не нужна, она удаляется или анонимизируется."
            }
          },
          userRights: {
            title: "7. Ваши права на конфиденциальность",
            text: "В зависимости от вашего местоположения, вы можете иметь право:",
            rightsDetails: {
              access: "Получить доступ, исправить, удалить или запросить копию ваших данных",
              objection: "Оспаривать определенную обработку данных или ограничить ее",
              withdrawConsent: "Отозвать согласие на маркетинговые коммуникации",
              complaint: "Подать жалобу в местный орган защиты данных",
              complaint_info: 'Чтобы воспользоваться этими правами, свяжитесь с нами, используя указанные ниже методы. Мы ответим на проверенные запросы в течение 30 дней.'
            },
            contactInstruction: "Чтобы воспользоваться этими правами, свяжитесь с нами, используя методы, указанные ниже. Мы ответим на проверенные запросы в течение 30 дней."
          },
          securityMeasures: {
            title: "8. Меры безопасности",
            text: "Мы очень серьезно относимся к безопасности ваших данных и используем физические, технические и административные меры защиты в соответствии с отраслевыми стандартами, включая:",
            securityDetails: {
              encryption: "Шифрование данных в покое и при передаче",
              firewalls: "Брандмауэры, контроль доступа и системы обнаружения вторжений",
              accessPolicies: "Строгие политики доступа сотрудников и обучение правилам безопасности",
              text: 'В маловероятном случае утечки данных мы оперативно уведомим пострадавших пользователей и примем немедленные меры для защиты наших систем.'
            },
            dataBreachAction: "В маловероятном случае утечки данных мы оперативно уведомим пострадавших пользователей и примем немедленные меры для защиты наших систем."
          },
          internationalDataTransfers: {
            title: "9. Международные передачи данных",
            text: "Ваша информация может передаваться и обрабатываться в странах, отличных от вашей собственной, включая США и Европейский Союз. В этих странах могут действовать другие законы о защите данных.",
            protectionMeasures: "Однако мы обеспечиваем наличие соответствующих мер защиты, таких как стандартные договорные положения, одобренные Европейской комиссией, для защиты ваших прав и свобод.",
            consent: "Используя EasyToVPN, вы даете согласие на передачу ваших данных в соответствии с этим описанием.",
            info: 'Тем не менее, мы обеспечиваем наличие соответствующих мер защиты, таких как стандартные договорные положения, одобренные Европейской комиссией, для защиты ваших прав и свобод. Используя EasyToVPN, вы соглашаетесь на передачу ваших данных в соответствии с описанием.'
          },
          businessTransfer: {
            title: "10. Передача бизнеса",
            text: "Если EasyToVPN будет участвовать в слиянии, поглощении или продаже активов, ваша личная информация может быть передана приобретенной организации. Тем не менее, она будет по-прежнему подчиняться обязательствам, предоставленным в Политике конфиденциальности, действующей на момент передачи."
          },
          childrenPolicy: {
            title: "11. Политика в отношении детей",
            text: "EasyToVPN не предназначен для использования детьми до 18 лет. Мы сознательно не собираем личную информацию несовершеннолетних. Если мы обнаружим, что у нас есть данные ребенка младше 18 лет, мы немедленно их удалим."
          },
          policyUpdates: {
            title: "12. Обновления этой политики",
            text: "Мы можем время от времени обновлять нашу Политику конфиденциальности, чтобы отразить изменения в наших практиках или сервисах. Мы уведомим вас о существенных изменениях по электронной почте или через заметное сообщение на наших веб-сайтах до вступления изменений в силу. Ваше дальнейшее использование EasyToVPN после изменений означает принятие обновленной Политики конфиденциальности."
          },
          contact: {
            title: "13. Контакты",
            text: "Если у вас есть вопросы по данной Политике конфиденциальности или нашим методам обработки данных, пожалуйста, свяжитесь с нами следующими способами:",
            details: {
              email: "Электронная почта: easytovpn.com@gmail.com",
              telegram: "Telegram: https://t.me/vpnMyServiceBot",
              lastUpdated: "Последнее обновление: 10.12.2023"
            }
          }
        },

        aboutUs: {
          paragraph1: 'Добро пожаловать в EasyToVPN! Мы — команда, которая верит в право каждого человека на свободу интернета и защиту конфиденциальности. Наша миссия — обеспечить вам безопасный, быстрый и неограниченный доступ к интернету независимо от того, где вы находитесь.',
          paragraph2: 'Высокий уровень безопасности: Мы используем современные протоколы шифрования, чтобы защитить ваши данные от хакеров и посторонних глаз. Анонимность: Ваши действия в интернете остаются конфиденциальными благодаря строгой политике отсутствия журналов (no-logs policy). Скорость и стабильность: Наши сервера оптимизированы для обеспечения высокой скорости соединения и беспрерывной работы. Глобальная сеть серверов: Мы предоставляем доступ к серверам в десятках стран, чтобы вы могли наслаждаться контентом без ограничений. Простота использования: Удобные приложения для всех ваших устройств позволяют настроить VPN за считанные секунды.',
          paragraph3: 'Мы убеждены, что приватность — это не роскошь, а базовое право. В мире, где персональные данные становятся новой валютой, наша задача — помочь вам сохранить контроль над своей цифровой жизнью.',
          paragraph4: 'Присоединяйтесь к нам и сделайте интернет безопасным и свободным!',
          paragraph5: 'Подробнее о наших услугах и ценностях вы можете узнать на нашем сайте.',
          aboutUsTitle1: 'О нас',
          aboutUsTitle2: 'Почему выбирают нас:',
          aboutUsTitle3: 'Наша философия',
        },
        passwordReminder: {
          title: 'Введите адрес электронной почты, на который будет отправлен новый пароль.',
          placeholder: 'Введите свой адрес электронной почты',
          send: 'Отправить'
        },
        mail: {
          mailOk: 'Новый пароль отправлен на ваш email',
          mailError: 'Неверный или отсутствующий электронный адрес'
        },
        infoTariff: {
          tariff: 'Тариф',
          amount: 'Сумма',
          date: 'Дата операции',
          finish_date: 'Дата завершения',
          noHistory: 'Нет истории продлений'
        },
        selectOfMonths: 'Выберите количество месяцев:',
        period: 'Период',
        tariff: 'Тариф',
        locations: 'Локация',
        selectFilter: {
          plan: 'Текущий тарифный план:',
          status: 'Статус:',
          date: 'Дата'
        },
        per_mounth: 'За месяц',
        custom_pricing: 'По договоренности',
        footer: {
          company: {
            title: "КОМПАНИЯ:",
            item1: 'О нас',
            item2: 'Контакты',
            item3: 'Политика конфиденциальности',
            item4: 'Условия использования',
          },
          customerCenter: {
            title: "Центр обслуживания клиентов:",
            item: 'Личный кабинет'
          },
          contacts: "Контакты:",
          ourSocialMedia: 'Мы в социальных сетях:'
        },

        advantages: {
          title: 'Почему стоит выбрать нас?',
          speed: {
            title: 'СКОРОСТЬ',
            description: 'Скорость от 500 Мбит/с'
          },
          fastSupport: {
            title: 'БЫСТРАЯ ПОДДЕРЖКА',
            description: 'Опытная и быстрая техническая поддержка'
          },
          refund: {
            title: 'ВОЗВРАТ',
            description: 'Возврат доступен в течении 30-дней, если же из-за нашей вины вы не смогли пользоваться услугой'
          },
          customizing: {
            title: 'НАСТРОЙКА',
            description: 'Помощь в настройке и использовании'
          }
        },
        descriptionVPN: {
          title: 'Что вам это даёт',
          descriptionItem1: {
            title: 'Повышенная безопасность - ',
            text: 'шифрование данных защищает от хакеров и утечек информации, особенно в сетях публичного Wi-Fi.'
          },
          descriptionItem2: {
            title: 'Анонимность и конфиденциальность - ',
            text: 'скрытие вашего реального IP-адреса и предотвращение отслеживания активности в Интернете.'
          },
          descriptionItem3: {
            title: 'Доступ к заблокированным ресурсам - ',
            text: 'обход географических блокировок и цензуры (например, доступ к Netflix и заблокированным сайтам).'
          },
          descriptionItem4: {
            title: 'Защита от слежки - ',
            text: 'предотвращение отслеживания вашей активности рекламными сетями и интернет-провайдерами.'
          },
          descriptionFooter: 'Почему выбирают нас?'
        },
        country: {
          belgium: 'Бельгия',
          czech: 'Чехия',
          france: 'Франция',
          german: 'Германия',
          great_britain: 'Великобритания',
          greek: 'Греция',
          italy: 'Италия',
          netherlands: 'Нидерланды',
          span: 'Испания',
          usa: 'США',
        },
        settings: {
          setup1: 'Скачайте клиент Outline из App Store или Google Play, затем подключитесь с помощью уникального ключа доступа. Клиентское приложение можно установить на ваш компьютер, телефон или планшет для безопасного серфинга в Интернете и обмена данными.',
          setup2: 'Добавьте токен активации VPN из вашего личного кабинета',
          setup3: 'Подключите собственный VPN',
          setup4: 'Проверьте свой новый IP и используйте любимые сайты!',
        },

        continue: 'ПРОДЛИТЬ ТАРИФ',
        month: 'месяц',
        payment_summ: "Введите сумму пополнения в USDT",
        contactUs: "Свяжитесь с нами",
        info: "Вот некоторая информация о нашей компании и услугах...",
        contactTelegram: "Связаться через Telegram",
        telegramLink: "Если у вас есть вопросы или вам нужна поддержка, свяжитесь с нами через Telegram:",
        qrCodeText: "Отсканируйте этот QR-код, чтобы связаться с нами в Telegram:",
        botName: "@vpnMyServiceBot",
        dashboard: 'Панель пользователя',
        personalInfo: 'Личная информация',
        name: 'Имя',
        email: 'Электронная почта',
        phone: 'Телефон',
        address: 'Адрес',
        balance: 'Баланс',
        selectPaymentMethod: 'Пополнить баланс',
        balanceReplenishment: 'Пополнение баланса',
        payWithCrypto: 'Оплатить криптовалютой',
        selectPlan: 'Доступные сейчас планы',
        selectMonths: 'Твой заказ',
        numberOfMonths: 'Количество месяцев',
        confirmSelection: 'Подтвердить выбор',
        paymentInformation: 'Информация о платеже',
        amount: 'Сумма',
        limitTariff: 'Лимит',
        days: 'Дней осталось',
        location: 'Расположение',
        transactionId: 'ID транзакции',
        scanToPay: 'Сканируйте для оплаты',
        qrCodeAlt: 'QR код для оплаты',
        subscriptionConfirmation: 'Подтверждение подписки',
        successfullyActivated: 'Вы успешно активировали тариф',
        forMonths: 'на',
        months: 'месяцев.',
        yourAccessCode: 'Ваш код доступа',
        saveAccessCode: 'Пожалуйста, сохраните этот код для дальнейшего использования.',
        insufficientFunds: 'Недостаточно средств',
        notEnoughBalance: 'У вас недостаточно баланса для выбора этого тарифа.',
        tariffsHistory: 'Активные ключи',
        service: 'Текущий тарифный план:',
        startDate: 'Дата начала',
        finishDate: 'Дата окончания',
        vpnCode: 'VPN код',
        status: 'Статус',
        copy: 'Скопировано',
        noServiceHistory: 'История услуг недоступна.',
        showMore: 'Показать больше...',
        transactionsHistory: 'История транзакций',
        date: 'Дата',
        noTransactionHistory: 'История транзакций недоступна.',
        confirmAlert: {
          title: 'Вы выбрали тариф',
          message: 'Вы уверены, что хотите подключить тариф на',
          months: 'месяцев?',
          yes: 'Да',
          no: 'Нет',
        },
        auth: {
          login: 'Войти',
          signup: 'Регистрация',
          username: 'Логин/Электронная почта',
          password: 'Пароль',
          confirmPassword: 'Подтверждение пароля',
          noAccount: "Нет аккаунта? Зарегистрируйтесь",
          haveAccount: 'Уже есть аккаунт? Войти',
          forgotPassword: 'Забыли пароль',

        },
        banner: {
          title: 'Защитите свою конфиденциальность с нашим VPN',
          description: 'Защитите свою онлайн-активность и безопасно просматривайте интернет с помощью нашего надежного VPN-сервиса.',
          getStarted: 'Начать',
          buyNow: 'Подключить',
          country: 'Попробовать'

        },
        pricing: {
          title: 'Наши цены',
          basicPlan: {
            title: 'Базовый план',
            price: '5$/месяц',
            traffic: '500 ГБ трафика',
            description1: 'Для базовых домашних пользователей',
            description2: 'Идеально для просмотра, стриминга и социальных сетей.'
          },
          familyPlan: {
            title: 'Семейный план',
            price: '10$/месяц',
            traffic: '2 ТБ трафика',
            description1: 'Для семьи',
            description2: 'Идеально для нескольких устройств и активных пользователей.'
          },
          premiumPlan: {
            title: 'Премиум план',
            price: '25$/месяц',
            traffic: 'Неограниченный трафик',
            description1: 'Для активных интернет-пользователей',
            description2: 'Неограниченный доступ к интернету без ограничений.'
          },
          dedicatedServer: {
            title: 'Выделенный сервер',
            price: 'Индивидуальное ценообразование',
            traffic: 'Трафик по запросу',
            description1: 'Для специальных нужд',
            tg_conttact: "https://t.me/vpnMyServiceBot",
            description2: 'Персональный сапорт'
          }
        },
        features: {
          title: 'Почему выбирают нас?',
          feature1: 'Надежное и стабильное соединение',
          feature2: 'Гибкие планы для любого пользователя',
          feature3: 'Поддержка 24/7',
          feature4: 'Индивидуальные решения для уникальных потребностей',
          feature5: 'Прозрачные условия без скрытых сборов'
        },
        // footer: {
        //     allRightsReserved: 'Все права защищены.',
        //     aboutUs: 'О нас',
        //     termsOfUse: 'Условия использования',
        //     privacyPolicy: 'Политика конфиденциальности',
        //     contact: 'Контакты'
        // },
        profile: 'Профиль',
        logout: 'Выход',
        login: 'Войти',
        register: 'Регистрация',
        changeLang: 'Изменить язык',
      },
    },
  },
  lng: 'en', // За замовчуванням
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
