import React from 'react';
import './advantages.css';
import { useTranslation } from 'react-i18next';
import fast from './icon/fast.png';
import refund from './icon/refund.png';
import setting from './icon/setting.png';
import speed from './icon/speed.png';



function Advantages() {
    const { t } = useTranslation();

    return (
        <div>
            <div className='advantagesTitle'>
                {t('advantages.title')}
            </div>

            <div className='advantages'>
                <div className='item_advantages item_speed'>
                    <img src={speed} alt='speed'></img>
                   <div className='underHeading'><h2>{t('advantages.speed.title')}</h2></div> 
                    <h4>{t('advantages.speed.description')}</h4>
                </div>

                <div className='item_advantages'>
                    <img src={fast} alt='fast'></img>
                    <div className='underHeading'><h2>{t('advantages.fastSupport.title')}</h2></div>
                    <h4>{t('advantages.fastSupport.description')}</h4>
                </div>

                <div className='item_advantages item_refund'>
                    <img src={refund} alt='refund'></img>
                    <div className='underHeading'><h2>{t('advantages.refund.title')}</h2></div>
                    <h4 className='refund'>{t('advantages.refund.description')}</h4>
                </div>

                <div className='item_advantages item_setting'>
                    <img src={setting} alt='setting'></img>
                    <div className='underHeading'><h2>{t('advantages.customizing.title')}</h2></div>
                    <h4>{t('advantages.customizing.description')}</h4>
                </div>
            </div>

            

        </div>
    );
}

export default Advantages;
