import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';  // Імпортуємо Link з react-router-dom
import { useTranslation } from 'react-i18next';  // Імпортуємо useTranslation
import './home.css';
import header from './header.png';
import SocialIcon from '../socialIcon';
import star1 from './star1.png';
import star2 from './star2.png';
import star3 from './star3.png';
import star4 from './star4.png';
import servers from './servers.png';
import IconCountry from '../iconCountry';
import Setup from '../Setup';
import Description from '../Description';
import Advantages from '../Advantages';




const selectState = (state) => ({
    userData: state.swapi.userData,
});

function Home() {
    const { t } = useTranslation();  // Використовуємо useTranslation
    const state = useSelector(selectState);
    const { userData } = state;

    return (
        <>
            <section className='banner'>
                <div className='container'>
                    <img
                        src={header}
                        alt=""
                        className="header_image"
                    />
                    <h1>{t('banner.title')}</h1>
                    <p>{t('banner.description')}</p>
                    <div className="social-icons">
                        <SocialIcon />
                    </div>
                    <Link to={userData?._id ? '/admin/user/' + userData?._id : '/login'}>
                        <button>{t('banner.getStarted')}</button>
                    </Link>
                </div>
            </section>

            <section className='pricing home'>
                <div className='container'>
                    <div className='pricing-options'>

                        <div className="pricing-card">
                            <div style={{ background: 'linear-gradient(to bottom, #da77f2, #ff77a9)' }}>
                                <div style={{ padding: '52px' }}>
                                    <div className="pricing-header">
                                        <p className="pricing-price">{t('pricing.basicPlan.price')}</p>
                                    </div>
                                </div>
                                <h3 className="pricing-title">{t('pricing.basicPlan.title')}</h3>
                            </div>
                            <div className="pricing-features">
                                <div className='info_tariff'>
                                    <div>
                                        <img src={star1} alt='star1'></img>
                                    </div>
                                    <div>{t('pricing.basicPlan.traffic')}</div>
                                </div>
                                <div className='info_tariff'><img src={star1} alt='star1'></img>{t('pricing.basicPlan.description1')}</div>
                                <div className='info_tariff'><img src={star1} alt='star1'></img>{t('pricing.basicPlan.description2')}</div>
                                <Link to={userData?._id ? '/admin/user/' + userData?._id : '/login'}>
                                    <button className="pricing-btn">{t('banner.buyNow')}</button>
                                </Link>
                            </div>

                        </div>

                        <div className="pricing-card family">
                            <div style={{ background: 'linear-gradient(180deg, #a58eff, #e3c2ff)' }}>
                                <div style={{ padding: '52px' }}>
                                    <div className="pricing-header-family">
                                        <p className="pricing-price-family">{t('pricing.familyPlan.price')}</p>
                                    </div>
                                </div>
                                <h3 className="pricing-title-family">{t('pricing.familyPlan.title')}</h3>
                            </div>
                            <div className="pricing-features">
                                <div className="info_tariff">
                                    <img src={star2} alt="star2"></img>{t('pricing.familyPlan.traffic')}
                                </div>
                                <div className="info_tariff">
                                    <img src={star2} alt="star2"></img>{t('pricing.familyPlan.description1')}
                                </div>
                                <div className="info_tariff">
                                    <img src={star2} alt="star2"></img>{t('pricing.familyPlan.description2')}
                                </div>
                                <Link to={userData?._id ? '/admin/user/' + userData?._id : '/login'}>
                                    <button className="pricing-btn btn_family">{t('banner.buyNow')}</button>
                                </Link>
                            </div>
                        </div>

                        <div className="pricing-card premium">
                            <div style={{ background: 'linear-gradient(180deg, #32a852, #74db8c)' }}>
                                <div style={{ padding: '52px' }}>
                                    <div className="pricing-header-premium">
                                        <p className="pricing-price-premium">{t('pricing.premiumPlan.price')}</p>
                                    </div>

                                </div>
                                <h3 className="pricing-title-premium">{t('pricing.premiumPlan.title')}</h3>
                            </div>
                            <div className="pricing-features">
                                <div className="info_tariff">
                                    <img src={star3} alt="star3"></img>{t('pricing.premiumPlan.traffic')}
                                </div>
                                <div className="info_tariff">
                                    <img src={star3} alt="star3"></img>{t('pricing.premiumPlan.description1')}
                                </div>
                                <div className="info_tariff">
                                    <img src={star3} alt="star3"></img>{t('pricing.premiumPlan.description2')}
                                </div>
                                <Link to={userData?._id ? '/admin/user/' + userData?._id : '/login'}>
                                    <button className="pricing-btn btn_premium">{t('banner.buyNow')}</button>
                                </Link>
                            </div>
                        </div>

                        <div className="pricing-card dedicated">
                            <div style={{ background: 'linear-gradient(180deg, #5e17eb, #31087b)' }}>
                                <div style={{ padding: '52px' }}>
                                    <div className="pricing-header-dedicated">
                                        <img src={servers} alt="servers"></img>
                                        <p className='pricing-servers-dedicated'>{t('pricing.dedicatedServer.price')}</p>
                                    </div>
                                </div>
                                <h3 className="pricing-title-dedicated">{t('pricing.dedicatedServer.title')}</h3>
                            </div>

                            <div className="pricing-features">
                                <div className="info_tariff">
                                    <img src={star4} alt="star4"></img>{t('pricing.dedicatedServer.traffic')}
                                </div>
                                <div className="info_tariff">
                                    <img src={star4} alt="star4"></img>{t('pricing.dedicatedServer.description1')}
                                </div>
                                <div className="info_tariff">
                                    <img src={star4} alt="star4"></img>{t('pricing.dedicatedServer.description2')}
                                </div>
                                <a
                                    className="contact-link-dedicated"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={t('pricing.dedicatedServer.tg_conttact')}
                                >
                                    {t('pricing.dedicatedServer.tg_conttact')}
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className='features'>
                <hr className="custom-hr"></hr>

                <div className='container'>
                    <h1>{t('features.title')}</h1>  {/* Переклад заголовку розділу "Особливості" */}
                    <p>{t('features.feature1')}</p>
                    <IconCountry />
                </div>
            </section>

            <section className='easySetup'>
                <div className='setup_content'><Setup /></div>
            </section>

            <section className='description-section'>
                <Description />
            </section>

            <section className='why-choose-us'>
                <Advantages />
            </section>


            {/* <footer className='footer'>
               <Footer/>
               
            </footer> */}
        </>
    );
}

export default Home;
