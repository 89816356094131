/* eslint-disable no-unused-vars */
/* eslint-disable no-extra-parens */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UsersRouter } from './users';

import { UserRouter } from './user';

const selectState = (state) => ({
    isLogged: state.swapi.isLogged,
    userData: state.swapi.userData,
});
export const AccountRouter = (props) => {
    const { isLogged, userData } = useSelector(selectState);
    const navigate = useNavigate();

    // Перенаправлення на логін якщо не залогінений
    useEffect(() => {
        if (!isLogged) {
            navigate('/');
        }
    }, [ isLogged, navigate ]);

    return (
        <>
            {userData && (
                <>
                    <UserRouter { ...props } />
                </>

            )}
               {userData.role === 'admin' && (
                <>
                    <UsersRouter { ...props } />
                </>

            )}
        </>
    );
};