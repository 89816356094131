import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css'; // Імпортуємо CSS файл
import mail from './icon/email.png'
import discord from './icon/discord.png'
import facebook from './icon/facebook.png'
import reddit from './icon/reddit.png'
import skype from './icon/skype.png'
import telegram from './icon/telegram.png'
import youtube from './icon/youtube.png'
import { api } from '../../Api';

function Contact() {
  const { t } = useTranslation();

  // Стан для форми

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');
  const [message, setMessage] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await api.postData(
      {
        name: name,
        email: email,
        question: question,
        message: message
      },
      '/sendMessage',
    );
  }

  return (
    <div className="contact-container">
      <div className="contact-wrap">
        <h1 className='contactTitle'>{t('contactPage.title')}</h1>
        <div className='contact_content'>
          <div className='contact_bloc1'>
            <div className='item'>
              <a href="mailto:easytovpn.com@gmail.com">
                <img src={mail} alt='mail' />
                easytovpn.com@gmail.com
              </a>
            </div>
            <div className='item'>
              <a href="https://join.skype.com/invite/Fjxc9DRvApoo" target='blank' rel="noopener noreferrer">
                <img src={skype} alt='skype' />
                Skype
              </a>
            </div>
            <div className='item'>
              <a href="https://t.me/easytovpn" target="_blank" rel="noopener noreferrer">
                <img src={telegram} alt="telegram" />
                Telegram
              </a>

            </div>
            <h3 className='followUs'>{t('contactPage.follow')}</h3>
            <div className='item'>
              <div className='noLink'>
                <img src={facebook} alt='facebook' />
                Facebook
              </div>
            </div>
            <div className='item'>
              <div className='noLink'>
                <img src={youtube} alt='youtube' />
                Youtube
              </div>
            </div>
            <div className='item'>
              <div className='noLink'>
                <img src={discord} alt='discord' />
                Discord
              </div>
            </div>
            <div className='item'>
              <div className='noLink'>
                <img src={reddit} alt='reddit' />
                Reddit
              </div>
            </div>
            <div className='item'>
              <a href="https://t.me/easytovpn" target="_blank" rel="noopener noreferrer">
                <img src={telegram} alt='telegram' />
                Telegram
              </a>
            </div>
            <div className='address'>
              <h3 className='addressTitle'>{t('contactPage.address')}</h3>
              <div style={{ textAlign: 'left' }}>
                Profiti Ilias 4, Germasogeia 4046, Cyprus
              </div>
            </div>
          </div>
          <div className='contact_bloc2'>
            <h3 style={{ marginBottom: '20px' }}>{t('contactPage.sendMessage')}</h3>
            <form className='sendMail' onSubmit={handleSubmit}>
              <div className='send_name'>
                <label htmlFor='nameInput'>{t('contactPage.name')}</label>
                <input
                  id='nameInput'
                  placeholder={t('contactPage.name')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className='send_email'>
                <label htmlFor='emailInput'>E-mail:</label>
                <input
                  id='emailInput'
                  placeholder={t('contactPage.email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='send_question'>
                <label htmlFor='question_send'>{t('contactPage.question')}</label>
                <select
                  id="question_send"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                >
                  <option value="vpn-anonymity">Может ли VPN действительно обеспечить мне анонимность в сети?</option>
                  <option value="vpn-access-blocked-sites">Как получить доступ к заблокированным сайтам с помощью VPN?</option>
                  <option value="vpn-support">Как я могу связаться со службой поддержки для устранения неполадок?</option>
                  <option value="vpn-data-logs">Ведет ли VPN логи моих данных или действий?</option>
                </select>

              </div>

              <div className='send_message'>
                <label htmlFor='message'>{t('contactPage.message')}</label>
                <textarea
                  id='message'
                  placeholder={t('contactPage.enterMessage')}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="sendBtn-container">
                <button className="sendBtn" type="submit">{t('contactPage.sendBtn')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
