import React from 'react';
import './setup.css';
import { useTranslation } from 'react-i18next';
import img1 from './icon/img1.png';
import img2 from './icon/img2.png';
import img3 from './icon/img3.png';
import img4 from './icon/img4.png';
import img5 from './icon/img5.png';
import img6 from './icon/img6.png';



function Setup() {
    const { t } = useTranslation();

    return (
        <div className='setup'>
            <div className='block1'>
                <div className='settings'>

                    {/* Setup 1 */}
                    <div className='setup1'>
                        <div className='num'>1</div>
                        <p>{t('settings.setup1')}</p>
                    </div>

                    {/* Setup 2 */}
                    <div className='setup2'>
                        <div className='num'>2</div>
                        <div className='setupInfo'>
                            <p className='setup2_p'>{t('settings.setup2')}</p>
                            <img className='img_setup img_setup2' src={img4} alt='img'></img>
                            <img className='img_arrow2' src={img6} alt='img'></img>
                            <img className='img3' src={img3} alt='img'></img>
                            <img className='img_arrow3' src={img5} alt='img'></img>
                        </div>
                    </div>

                    {/* Setup 3 */}
                    <div className='setup3'>
                        <div className='num'>3</div>
                        <div className='setupInfo'>
                            <p>{t('settings.setup3')}</p>
                            <img className='img_setup' src={img1} alt='img'></img>
                            <img className='img_arrow1' src={img6} alt='img'></img>
                        </div>
                    </div>
                </div>
            </div>

            {/* Setup 4 */}
            <div className='setup4'>
                <div className='setup4_block'>
                    <div className='num'>4</div>
                    <p>{t('settings.setup4')}</p>
                </div>
                <div>
                    <img className='img_setup' src={img2} alt='img'></img>
                    <img className='img_arrow' src={img6} alt='img'></img>
                </div>
            </div>
        </div>

    );
}

export default Setup;
